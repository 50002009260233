import { ReactNode, useState } from 'react';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Svgs } from '../assets/svg';

interface ClickableListProps<T> {
  items: T[];
  initialSelectedId?: string | number;
  renderItem: (item: T) => ReactNode;
  getItemKey: (item: T) => any;
  onItemClick: (val: any) => Promise<boolean>;
}

function ClickableList<T>({
  items,
  initialSelectedId,
  renderItem,
  getItemKey,
  onItemClick,
}: ClickableListProps<T>) {
  const [selectedItemId, setSelectedItemId] = useState(initialSelectedId);
  const [loadingItemId, setLoadingItemId] = useState<string | number | null>(null);

  const handleItemClick = async (itemId: string | number) => {
    if (loadingItemId !== null) return;

    setLoadingItemId(itemId);
    try {
      const success = await onItemClick(itemId);
      if (success) {
        setSelectedItemId(itemId);
      }
    } catch (error) {
      console.error('Error selecting item:', error);
    } finally {
      setLoadingItemId(null);
    }
  };

  return (
    <List style={{ width: '100%' }}>
      {items.map(item => {
        const itemId = getItemKey(item);
        const isSelected = selectedItemId === itemId;
        const isLoading = loadingItemId === itemId;

        return (
          <ListItem key={itemId} className='clickableListItem'>
            <ListItemButton onClick={() => handleItemClick(itemId)}>
              <ListItemText
                primary={
                  <Typography
                    variant='p16'
                    color='secondary.dark'
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {renderItem(item)}
                    {isSelected && !isLoading && <Svgs.IconCheckmark />}
                    {isLoading && <CircularProgress size={20} />}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default ClickableList;
