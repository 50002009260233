import { Button, Grid, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { Column, RightDrawer, Row, Spacer } from '../../components';
import { useContext, useEffect, useState } from 'react';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { LicenseMetricsData } from '../../api/apis/LicenseMetricsApi';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import {
  SubscriptionApi,
  SubscriptionData,
  SubscriptionRest,
} from '../../api/apis/SubscriptionApi';
import { SubscriptionCompartments, subscriptionStorageKey } from '../../data/subscription';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { IntervalTypeEnum, PricebookBundle } from '../../models/PricebookData';
import { RightDrawerProps } from '../../components/RightDrawer';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { PaymentCompartments, paymentStorageKey } from '../../data/payment';
import { TermsApi } from '../../api/apis/TermsApi';
import { useLoading } from '../../hooks/useLoading';
import LicenseAtAGlance from './LicenseAtAGlance';
import { Bold } from '../../components/Bold';
import { pluralize } from '../../helpers/utilityFunctions';
import LicensePaymentDetails from './LicensePaymentDetails';
import { LicenseAgreementApi } from '../../api/apis/LicenseAgreementApi';

interface PurchaseLicensesDrawerProps extends RightDrawerProps {
  pricebook: PricebookBundle;
}

const PurchaseLicensesDrawer: React.FC<PurchaseLicensesDrawerProps> = ({
  open,
  setOpen,
  pricebook,
}) => {
  const navigate = useNavigate();
  const appStorage = useAppStorage();
  const { setToast } = useContext(ToastbarContext);
  const { setLoading } = useLoading();
  const [tosLink, setTosLink] = useState<string>();
  const [activeSubscription, setActiveSubscription] = useState<SubscriptionData>();
  const [invoiceDate, setInvoiceDate] = useState<string>('');

  const subscriptionApi = useService<SubscriptionApi>(ApiKeys.Subscription);
  const licenseAgreementApi = useService<LicenseAgreementApi>(ApiKeys.LicenseAgreement);
  const termsApi = useService<TermsApi>(ApiKeys.Terms);

  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const pricebookSelections =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections')) ?? [];
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const licenseMetrics =
    useObservable(licenseDataCache.observe$<LicenseMetricsData[]>('licenseMetrics')) ?? undefined;
  const license = licenseMetrics?.find(license => license.pricebookId === pricebook.pricebook.id);

  const subscriptionDataCache =
    appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
  const subscription =
    useObservable(subscriptionDataCache.observe$<SubscriptionData[]>('subscription')) ?? undefined;
  const paymentDataCache = appStorage.retrieve<PaymentCompartments>(paymentStorageKey);

  const currSubscription = pricebook?.prices.find(
    price => price.intervalType !== IntervalTypeEnum.None,
  );
  const price = (currSubscription && currSubscription.price / 100.0) ?? 0;

  const getLicenseChangeMessage = (values: any) => {
    const licenseChange = values.licenseCount - (license?.purchasedLicenses ?? 0);
    if (licenseChange === 0) return "You haven't made any changes yet.";
    const action = licenseChange > 0 ? 'adding' : 'removing';
    return (
      <span>
        You're {action} <Bold>{Math.abs(licenseChange)}</Bold> {pluralize(licenseChange, 'license')}
        .
      </span>
    );
  };

  const getSubmitButtonText = (values: any) => {
    const licenseChange = values.licenseCount - (license?.purchasedLicenses ?? 0);
    if (licenseChange === 0) return 'Purchase';
    const action = licenseChange > 0 ? 'Purchase' : 'Remove';
    return `${action} ${Math.abs(licenseChange)} ${pluralize(licenseChange, 'license')}`;
  };

  const onUpdateLicenses = async (values: any) => {
    try {
      setLoading(true);
      const licenseChange = values.licenseCount - (license?.purchasedLicenses ?? 0);

      if (licenseChange < 0 && Math.abs(licenseChange) > (license?.availableLicenses ?? 0)) {
        setToast(
          new Toast({
            message: "You're trying to remove too many licenses.",
            severity: 'error',
            open: true,
            autoHideDuration: 3000,
          }),
        );
        return;
      }

      const subscriptionData = {
        subscriptionId: activeSubscription?.id,
        amount: Math.abs(licenseChange),
      } as SubscriptionRest;

      if (licenseChange > 0) {
        const result = await subscriptionApi.add(subscriptionData);
        if (result.data?.paymentFailed === true) {
          const failedInvoiceUrl = result.data.failedInvoiceUrl;
          window.open(failedInvoiceUrl);
          setOpen(false);
          return;
        }
        navigate('/congrats', {
          state: {
            quantity: licenseChange,
            chosenPackage: pricebookSelections[0],
          },
        });
        setToast(
          new Toast({
            message: `${licenseChange} additional ${pluralize(
              licenseChange,
              'license',
            )} purchased!`,
            severity: 'success',
            open: true,
            autoHideDuration: 3000,
          }),
        );
      } else {
        await subscriptionApi.decrease(subscriptionData);
        setToast(
          new Toast({
            message: `${Math.abs(licenseChange)} ${pluralize(
              Math.abs(licenseChange),
              'license',
            )} removed!`,
            severity: 'success',
            open: true,
            autoHideDuration: 3000,
          }),
        );
        setOpen(false);
      }

      await Promise.all([
        subscriptionDataCache.reload('subscription'),
        paymentDataCache.reloadAll(),
      ]);
    } catch (err) {
      console.error(err);
      setToast(new Toast({ severity: 'error', message: 'Failed to update licenses', open: true }));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!subscription) {
      setActiveSubscription(undefined);
    }
    if (subscription) {
      // TODO this logic must change when there are more than 1 package to purchase additional licenses for
      const subscriptionId = subscription?.find(x => x.productId === currSubscription?.product.id);
      setActiveSubscription(subscriptionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricebook.prices, subscription]);

  useEffect(() => {
    const getTermsLink = async () => {
      const res = await termsApi.getAll();
      const termsObject = res.data.find(doc => doc.docDescription === 'Terms of Service');
      if (!termsObject) {
        return;
      }
      const url = termsApi.get(termsObject.id);
      setTosLink(url);
    };
    getTermsLink();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const generateLicense = async () => {
      if (open && pricebook.pricebook.id) {
        try {
          setLoading(true);
          const { data } = await licenseAgreementApi.generate(pricebook.pricebook.id);
          setInvoiceDate(data.invoiceDate);
        } catch (error) {
          console.error('Error generating license agreement:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    generateLicense();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, pricebook.pricebook.id]);

  const onClose = () => {
    setOpen(false);
  };

  if (!license) return null;

  return (
    <RightDrawer open={open} setOpen={setOpen}>
      <Form onSubmit={onUpdateLicenses} initialValues={{ licenseCount: license.purchasedLicenses }}>
        {({ handleSubmit, values }) => {
          const licenseChange = values.licenseCount - (license?.purchasedLicenses ?? 0);
          const isLicenseCountChanged = licenseChange !== 0;

          return (
            <Column>
              <Typography color='secondary.main' variant='p40'>
                Edit number of licenses
              </Typography>
              <Spacer height='xs' />
              <Field name='licenseCount'>
                {({ input }) => (
                  <LicenseAtAGlance
                    licenseMetrics={license}
                    pricebookBundle={pricebook}
                    value={input.value}
                    onChange={input.onChange}
                  />
                )}
              </Field>
              <Spacer height='xs' />
              <Grid container flexDirection='column' alignItems='center'>
                {!isLicenseCountChanged && (
                  <Typography variant='p16' color='secondary.main'>
                    {license.availableLicenses > 0 ? (
                      <>
                        You have <Bold>{license.availableLicenses}</Bold> unused{' '}
                        {pluralize(license.availableLicenses, 'license')}.
                      </>
                    ) : (
                      'All licenses have been claimed.'
                    )}
                  </Typography>
                )}
                <Typography variant='p16' color='secondary.main'>
                  {getLicenseChangeMessage(values)}
                </Typography>
              </Grid>
              <Spacer height='sm' />

              <Grid container display='inline-flex' flex={1} width='100%'>
                {isLicenseCountChanged && (
                  <LicensePaymentDetails
                    selectedPricebook={pricebook}
                    licenseQuantity={values.licenseCount}
                    addedLicenses={Math.max(licenseChange, 0)}
                    costPerLicense={price}
                    invoiceDate={invoiceDate}
                    hideSummary={licenseChange < 0}
                  />
                )}
              </Grid>

              <Spacer height='xs' />

              {isLicenseCountChanged && (
                <>
                  <Typography variant='p14' color='secondary'>
                    {licenseChange > 0
                      ? `*This amount may be prorated based on date of purchase. It will be automatically paid by the specified default payment method.`
                      : `*No refunds will be made for the time remaining on the current subscription period or for unused licenses.`}
                  </Typography>

                  <Spacer height='xxs' />

                  <Typography variant='p14' color='secondary'>
                    Please see our{' '}
                    <Link href={tosLink} target='_blank'>
                      Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link href='/cancellation-policy' target='_blank'>
                      Cancellation and Refund Policy
                    </Link>{' '}
                    for details.
                  </Typography>
                </>
              )}

              <Spacer height='xxl' />

              <Row style={{ justifyContent: 'space-between' }}>
                <Button onClick={onClose} variant='outlined' color='secondary' className='sm'>
                  Cancel
                </Button>
                {values.licenseCount !== license.purchasedLicenses && (
                  <Button onClick={handleSubmit} color='primary'>
                    {getSubmitButtonText(values)}
                  </Button>
                )}
              </Row>
            </Column>
          );
        }}
      </Form>
    </RightDrawer>
  );
};
export default PurchaseLicensesDrawer;
