import React from 'react';
import { Grid } from '@mui/material';
import { AnyReportType } from '../../../models/ReportData';
import { Spacer } from '../../../components';
import TotalUserAccounts from '../ReportUserAccounts/TotalUserAccounts';
import AvgDaysSinceLogin from './AvgDaysSinceLogin';
import PaymentPlatformChart from './PaymentPlatformChart';
import CreationMethodChart from './CreationMethodChart';
import PrimaryPlatformChart from './PrimaryPlatformChart';
import LoginCountChart from './LoginCountChart';
import EmptyReport from '../EmptyReport';

interface ReportUserDataProps {
  report: AnyReportType | null;
  summedReport: AnyReportType | null;
}

const ReportUserData: React.FC<ReportUserDataProps> = ({ report, summedReport }) => {
  if (!report) return <EmptyReport />;

  return (
    <>
      <Grid container flexDirection='row'>
        <TotalUserAccounts report={summedReport ?? report} showHelp />
        <AvgDaysSinceLogin report={summedReport ?? report} />
      </Grid>

      <Spacer height='md' />

      <Grid display='flex' flexDirection='row' justifyContent='space-between' margin='0 auto'>
        <Grid width='100%'>
          <PaymentPlatformChart report={summedReport ?? report} />
        </Grid>
        <Spacer width='xxl' />
        <Grid width='100%'>
          <CreationMethodChart report={summedReport ?? report} />
        </Grid>
      </Grid>

      <Spacer height='md' />

      <Grid display='flex' flexDirection='row' justifyContent='space-between' margin='0 auto'>
        <Grid width='100%'>
          <PrimaryPlatformChart report={summedReport ?? report} />
        </Grid>
        <Spacer width='xxl' />
        <Grid width='100%'>
          <LoginCountChart report={summedReport ?? report} />
        </Grid>
      </Grid>
    </>
  );
};

export default ReportUserData;
