import { AxiosResponse } from 'axios';
import type { AxiosInstance } from 'axios';
import { inject } from '@aesop-fables/containr';
import { ApiServices } from '../ApiServices';

export class LicenseMetricsApi {
  constructor(@inject(ApiServices.Axios) private axios: AxiosInstance) {}

  get(): Promise<AxiosResponse<LicenseMetricsData[]>> {
    return this.axios.get('license');
  }
}
export interface LicenseMetricsData {
  pricebookId: number;
  activeLicenses: number;
  purchasedLicenses: number;
  availableLicenses: number;
  pricebookName: string;
  artUrl: string;
  paidBy: string;
  paymentType: string;
}
