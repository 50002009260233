import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { AnyReportType } from '../../../models/ReportData';
import TotalUserAccounts from './TotalUserAccounts';
import { AdminTierTypeEnum } from '../../../services/drillDown';
import ReportBreakdown from './ReportBreakdown';
import EmptyReport from '../EmptyReport';
import UserAccountsTable from './UserAccountsTable';
import { UserAccountsTableData } from '../../../helpers/reportUtils';

interface ReportUserAccountsProps {
  report: AnyReportType | null;
  tier: AdminTierTypeEnum | undefined;
  summedReport: AnyReportType | null;
}

const ReportUserAccounts: React.FC<ReportUserAccountsProps> = ({ report, tier, summedReport }) => {
  const [isEmpty, setIsEmpty] = useState(false);
  const [tableData, setTableData] = useState<UserAccountsTableData | undefined>();

  const handleEmptyState = (value: boolean) => {
    setIsEmpty(value);
  };

  const handleTableData = (value: UserAccountsTableData) => {
    setTableData(value);
  };

  if (!report) {
    return <EmptyReport />;
  }

  return (
    <Grid>
      <TotalUserAccounts
        report={summedReport ?? report}
        tier={tier}
        tableData={tableData}
        isEmpty={isEmpty}
        showHelp
      />
      <UserAccountsTable
        fullReport={report}
        summedReport={summedReport}
        handleEmptyState={handleEmptyState}
        handleTableData={handleTableData}
      />
      <ReportBreakdown tier={tier} report={report} isEmpty={isEmpty} summedReport={summedReport} />
    </Grid>
  );
};

export default ReportUserAccounts;
