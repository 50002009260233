import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Row } from '../../components';
import { formatTwoDecimalPlaces } from '../../helpers/utilityFunctions';
import { IntervalTypeEnum, PricebookBundle } from '../../models/PricebookData';
import PaymentDetails from '../../components/PaymentDetails';

interface ContractPaymentDetailsProps {
  selectedPricebook: PricebookBundle | undefined;
  licenseQuantity: string;
  costPerLicense: number | undefined;
  invoiceDate: string;
  orgPays: boolean;
  meteredPayment: boolean | undefined;
  autoPay: boolean | undefined;
  prePaid: boolean;
}

const ContractPaymentDetails: React.FC<ContractPaymentDetailsProps> = ({
  selectedPricebook,
  licenseQuantity,
  costPerLicense,
  invoiceDate,
  orgPays,
  meteredPayment,
  autoPay,
  prePaid,
}) => {
  const activeSubscription = selectedPricebook?.prices.find(
    price => price.intervalType !== IntervalTypeEnum.None,
  );
  const price = costPerLicense ?? (activeSubscription && activeSubscription.price / 100.0) ?? 0;
  const costPerLicenseFormatted = `$${price}/${
    activeSubscription?.intervalType === IntervalTypeEnum.Year ? 'yr' : 'mo'
  }`;
  const title = `${
    orgPays && meteredPayment && autoPay ? 'Auto-pay' : orgPays ? 'Payment' : 'Member payment'
  } details`;
  const totalCost = parseInt(licenseQuantity) * price;

  const showInvoiceDate = orgPays && (!prePaid || (prePaid && !autoPay));
  const showLicenseQuantity = orgPays && prePaid;
  const meteredAutoPay = orgPays && meteredPayment && autoPay;
  const manualPrePaid = prePaid && !autoPay;

  const formFields = [
    {
      initialValue: selectedPricebook?.pricebook?.pricebookName || '',
      label: 'Package',
      fieldName: 'package',
    },
    ...(showInvoiceDate
      ? [
          {
            initialValue: invoiceDate,
            label: meteredAutoPay ? 'Auto-payment date' : 'Invoice date',
            fieldName: meteredAutoPay ? 'autoPaymentDate' : 'invoiceDate',
          },
        ]
      : []),
    ...(showLicenseQuantity
      ? [
          {
            initialValue: licenseQuantity,
            label: 'Number of licenses',
            fieldName: 'numberOfLicenses',
          },
        ]
      : []),
    {
      initialValue: costPerLicenseFormatted,
      label: 'Cost per license per member',
      fieldName: 'costPerLicense',
      inputStyle: { fontWeight: 'bold' },
    },
  ];

  const summaryContent = orgPays ? (
    <>
      <Row style={{ justifyContent: 'center' }}>
        <Typography color='info.main' style={{ display: 'inline' }} variant='subtitle1'>
          $
        </Typography>
        <Typography color='secondary.dark' style={{ display: 'inline' }} variant='subtitle1'>
          {meteredPayment ? 0 : formatTwoDecimalPlaces(totalCost)}
        </Typography>
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        <Typography variant='p12SemiBold' color='secondary.main'>
          {(manualPrePaid ? 'total cost' : 'due today') + '*'}
        </Typography>
      </Row>
    </>
  ) : (
    <Grid display='flex' flex={1} flexDirection='column' justifyContent='center' alignSelf='center'>
      <Typography variant='p12SemiBold' color='secondary.main'>
        Member pays
      </Typography>
    </Grid>
  );

  return <PaymentDetails title={title} formFields={formFields} summaryContent={summaryContent} />;
};

export default ContractPaymentDetails;
