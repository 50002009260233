export interface Pricebook {
  id: number;
  pricebookName: string;
  defaultPricebook: boolean;
  groupId: number;
  paidBy: string;
  productType: string;
  description: string[];
  logoUrl: string;
  artUrl: string;
  metadata: any;
  paymentType: string;
}

export interface Product {
  id: number;
  productName: string;
  productId: number;
  threeNickelsU: boolean;
}

export interface Processor {
  id: number;
  name: string;
}

export interface Price {
  id: number;
  productName: string;
  price: number;
  priceId: string;
  description: string;
  introductionPeriodInDays: number;
  subscriptionLengthInDays: number;
  intervalType: IntervalTypeEnum;
  product: Product;
  processor: Processor;
}

export interface PricebookBundle {
  pricebook: Pricebook;
  prices: Price[];
}

export enum PaymentTypeEnum {
  Metered = 'METERED_PAYMENT',
  Prepaid = 'PRE_PAID',
}

export enum ProductTypeEnum {
  Nickels = 'NICKELS',
  NickelsU = 'NICKELSU',
  GuidedChoice = 'GUIDED_CHOICE',
  Free = 'FREE',
}

export enum IntervalTypeEnum {
  Month = 'MONTH',
  Year = 'YEAR',
  None = 'NONE',
}
