import { FormControlLabel, Checkbox } from '@mui/material';

interface SingleCheckboxProps extends React.PropsWithChildren {
  label: string;
  value?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const SingleCheckbox: React.FC<SingleCheckboxProps> = props => {
  const { label, value, onChange, disabled } = props;
  return (
    <FormControlLabel
      label={label}
      control={<Checkbox checked={value} onChange={onChange} />}
      slotProps={{ typography: { variant: 'h3', color: 'secondary.dark' } }}
      disabled={disabled}
    />
  );
};

export default SingleCheckbox;
