import React, { useState, useRef, useEffect } from 'react';
import { Typography, Grid, Card, Button, useTheme } from '@mui/material';
import { Document, Page } from 'react-pdf';
import { DownloadButton, SingleCheckbox, Modal, Spacer } from '../../components';
import { Loading, useLoading } from '../../hooks/useLoading';
import { Svgs } from '../../assets/svg';

interface LicenseModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  docUri: string | undefined;
  reviewed: boolean | undefined;
  onDownload: () => void;
  onAgree: () => void;
}

const LicenseModal: React.FC<LicenseModalProps> = ({
  open,
  setOpen,
  docUri,
  reviewed,
  onDownload,
  onAgree,
}) => {
  const theme = useTheme();
  const { setLoading } = useLoading();
  const [checked, setChecked] = useState(false);
  const documentRef = useRef<HTMLDivElement>(null);
  const [isDocumentScrolled, setIsDocumentScrolled] = useState(false);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  const scrollToBottom = () => {
    if (documentRef.current) {
      documentRef.current.scrollTo({
        top: documentRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const handleScroll = () => {
    if (documentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = documentRef.current;
      const bottomThreshold = 50;
      const atEnd = scrollHeight - (scrollTop + clientHeight) <= bottomThreshold;

      if (atEnd && !isDocumentScrolled) {
        setIsDocumentScrolled(true);
      } else if (!atEnd && isDocumentScrolled) {
        setIsDocumentScrolled(false);
      }
    }
  };

  useEffect(() => {
    const currentDocumentRef = documentRef.current;
    if (currentDocumentRef && isDocumentLoaded) {
      currentDocumentRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentDocumentRef) {
        currentDocumentRef.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentLoaded]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      contentStyle={{
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      hideButtons
      fullWidth>
      <Grid
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          mb: 2,
        }}>
        <Grid
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            maxWidth: '80%',
            height: '100%',
            overflow: 'hidden',
            alignSelf: 'center',
          }}>
          <Grid
            sx={{
              display: 'inline-flex',
              flex: 1,
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mb: 3,
            }}>
            <Typography variant='p30' color='secondary'>
              Contract
            </Typography>
            <DownloadButton onDownload={onDownload} style={{ width: '150px', minHeight: '40px' }} />
          </Grid>

          <Grid
            ref={documentRef}
            sx={{
              display: 'flex',
              flex: 1,
              overflowX: 'hidden',
              overflowY: 'scroll',
              zIndex: 1,
              justifyContent: 'center',
            }}>
            <Document
              file={docUri}
              options={{ withCredentials: true, withXSRFToken: true }}
              onLoadSuccess={() => {
                setLoading(false);
                setIsDocumentLoaded(true);
              }}
              loading={
                <Grid container padding={5}>
                  <Loading />
                </Grid>
              }>
              <Grid mt={-15}>
                <Page
                  pageNumber={1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  scale={1.5}
                  width={Math.min(800, window.innerWidth * 0.9)}
                />
              </Grid>
            </Document>
          </Grid>
          <Grid>
            {!isDocumentScrolled && !reviewed && (
              <Grid
                onClick={scrollToBottom}
                sx={{
                  display: 'flex',
                  position: 'absolute',
                  bottom: 114,
                  left: 0,
                  right: 0,
                  padding: 2,
                  zIndex: 10,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  backdropFilter: 'blur(2px)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}>
                <Typography variant='p16SemiBold' color='primary.main'>
                  Scroll to bottom
                </Typography>
                <Spacer width='xxs' />
                <Svgs.IconArrowDown fill={theme.palette.secondary.main} />
              </Grid>
            )}
            {!reviewed ? (
              <Card variant='innerCard' sx={{ mt: 3, px: 2, alignContent: 'center' }}>
                <Grid
                  container
                  sx={{
                    padding: 2,
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}>
                  <>
                    <SingleCheckbox
                      label='I have read the license agreement, and I agree to it.'
                      onChange={() => isDocumentScrolled && setChecked(prev => !prev)}
                      disabled={!isDocumentScrolled}
                    />
                    <Button
                      onClick={onAgree}
                      color='primary'
                      style={{ width: '150px' }}
                      disabled={!checked || !isDocumentScrolled}>
                      Agree
                    </Button>
                  </>
                </Grid>
              </Card>
            ) : (
              <Grid pt={5} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LicenseModal;
