import React from 'react';
import DatePicker from '../../components/DatePicker';
import { Spacer } from '../../components';
import RefreshButton from '../../components/RefreshButton';
import { Dayjs } from 'dayjs';
import { Grid } from '@mui/material';
import { AdminTierTypeEnum } from '../../services/drillDown';

interface ReportsHeaderProps {
  selectedDate: Dayjs | null;
  onDateChange: (date: Dayjs | null) => void;
  onRefresh: () => void;
  earliestDate: Dayjs | null;
  isCurrentDate: boolean | undefined;
  today: Dayjs;
  tier: AdminTierTypeEnum | undefined;
}

const ReportsHeader: React.FC<ReportsHeaderProps> = ({
  selectedDate,
  onDateChange,
  onRefresh,
  earliestDate,
  isCurrentDate,
  today,
  tier,
}) => {
  return (
    <Grid display='flex' flexDirection='row'>
      {isCurrentDate && tier === AdminTierTypeEnum.Root && (
        <>
          <RefreshButton onRefresh={onRefresh} />
          <Spacer width='md' />
        </>
      )}
      <DatePicker
        initialDate={selectedDate}
        onDateChange={onDateChange}
        minDate={earliestDate}
        maxDate={today}
      />
    </Grid>
  );
};

export default ReportsHeader;
