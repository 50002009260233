import { useAppStorage, useObservable } from '@aesop-fables/scrinium';
import { Typography, Card, CardContent, Button, Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LicenseMetricsData } from '../../api/apis/LicenseMetricsApi';
import { Svgs } from '../../assets/svg';
import { Spacer, Row, Column } from '../../components';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { ProductTypeCompartments, productTypeStorageKey } from '../../data/productType';
import { filterByPayer, formatDollars } from '../../helpers/utilityFunctions';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import { PricebookBundle, PaymentTypeEnum, IntervalTypeEnum } from '../../models/PricebookData';
import PurchaseLicensesDrawer from '../license/PurchaseLicensesDrawer';
import MemberPaysMetricsCard from './MemberPaysMetricsCard';
import OrgPaysMetricsCard from './OrgPaysMetricsCard';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { OrganizationMetricsData } from '../../api/apis/OrgPricebookLicenseMetricsApi';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';

const AtAGlance: React.FC = () => {
  const navigate = useNavigate();
  const appStorage = useAppStorage();

  const [packagesAvailable, setPackagesAvailable] = useState(false);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [purchaseDrawerOpen, setPurchaseDrawerOpen] = useState(false);
  const [selectedPricebook, setSelectedPricebook] = useState<PricebookBundle | undefined>(
    undefined,
  );

  const productTypeDataCache = appStorage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const organizationDataCache =
    appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);

  const paidBy = useObservable(productTypeDataCache.observe$<string>('paidBy'));
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );
  const licenseMetrics = useObservable(
    licenseDataCache.observe$<LicenseMetricsData[]>('licenseMetrics'),
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pricebookOptions =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions')) ?? [];
  const pricebookSelections = useObservable(
    pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections'),
  );
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );
  const orgMetrics = useObservable(
    organizationDataCache.observe$<OrganizationMetricsData[]>('allMetrics'),
  );
  const selectedOrg = useObservable(
    organizationDataCache.observe$<OrganizationData>('organization'),
  );

  const pendingAgreement = licenseAgreements?.find(
    licenseAgreement => licenseAgreement.docStatus && !licenseAgreement.hasAccepted,
  );
  const meteredPayment = pricebookSelections?.some(
    pricebook => pricebook.pricebook.paymentType === PaymentTypeEnum.Metered,
  );
  const selectedOrgMetrics = useMemo(() => {
    if (selectedOrg?.id && orgMetrics) {
      return orgMetrics.find(metric => metric.organizationId === selectedOrg.id);
    }
    return undefined;
  }, [selectedOrg, orgMetrics]);

  useEffect(() => {
    const filteredPricebook = filterByPayer(pricebookOptions, paidBy);
    setPackagesAvailable(filteredPricebook.length > (pricebookSelections?.length ?? 0));
  }, [paidBy, pricebookOptions, pricebookSelections?.length]);

  useEffect(() => {
    let count = 0;
    licenseMetrics?.map(license => (count += license.activeLicenses));
    setTotalActiveUsers(count);
  }, [licenseMetrics]);

  const findPricebookById = (id: number): PricebookBundle | undefined => {
    const bundle = pricebookSelections?.find(pricebook => pricebook.pricebook.id === id);
    if (
      bundle &&
      bundle.pricebook &&
      (pricebookSelections?.filter(
        pricebook => pricebook.pricebook.pricebookName === bundle.pricebook.pricebookName,
      )?.length ?? 0) > 1
    ) {
      const bundleCopy = {
        pricebook: { ...bundle?.pricebook },
        prices: [...(bundle?.prices ?? [])],
      };
      const activeSubscription = bundleCopy?.prices.find(
        price => price.intervalType !== IntervalTypeEnum.None,
      );
      const price = (activeSubscription && activeSubscription.price / 100.0) ?? 0;

      bundleCopy.pricebook.pricebookName =
        bundleCopy.pricebook.pricebookName + ' (' + formatDollars(price) + ')';
      return bundleCopy;
    }
    return bundle;
  };

  const onPurchaseLicenses = (id: number) => {
    const pricebook = findPricebookById(id);
    setSelectedPricebook(pricebook);
    setPurchaseDrawerOpen(true);
  };

  const onAddPackage = () => {
    navigate('/addPackage', { state: { origin: 'dashboard' } });
  };

  return (
    <>
      <Typography variant='h1' color='secondary'>
        At a glance
      </Typography>
      <Spacer height='xxs' />
      <Card variant='ghost' color='primary'>
        <CardContent className='pad30'>
          {(licenseMetrics?.length ?? 0) === 0 ? (
            <Row style={{ justifyContent: 'center' }}>
              <Typography variant='h3' color='secondary'>
                You do not have any active license agreements.
              </Typography>
            </Row>
          ) : paidBy === 'ORG' && !meteredPayment ? (
            <Column>
              <Row style={{ alignItems: 'center' }}>
                <Svgs.IconMembersLarge />
                <Spacer width='xs' />
                <Typography variant='p20SemiBold' color='secondary'>
                  Active Users
                </Typography>
                <Spacer width='xs' />
                <Typography variant='p60Bold' color='secondary.dark'>
                  {totalActiveUsers}
                </Typography>
              </Row>
              <Spacer height='xxs' />
              {selectedOrgMetrics &&
                selectedOrgMetrics.pricebookLicenseMetricsList.map(metric => (
                  <OrgPaysMetricsCard
                    key={metric.pricebookId}
                    licenseMetric={metric}
                    onPurchaseLicenses={() => onPurchaseLicenses(metric.pricebookId)}
                    purchaseDisabled={typeof pendingAgreement !== 'undefined'}
                  />
                ))}
            </Column>
          ) : (
            <Column>
              <Row style={{ justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'center' }}>
                  <Svgs.IconMembersLarge />
                  <Spacer width='xs' />
                  <Typography variant='p20SemiBold' color='secondary'>
                    Active Users
                  </Typography>
                  <Spacer width='xs' />
                  <Typography variant='p60Bold' color='secondary.dark'>
                    {totalActiveUsers}
                  </Typography>
                </Row>
                {packagesAvailable && groupFeatureMapData?.canAddPackage && (
                  <Button
                    onClick={onAddPackage}
                    className='lg'
                    style={{ alignSelf: 'center' }}
                    color='primary'>
                    Add a package
                  </Button>
                )}
              </Row>
              <Spacer height='xxs' />
              {selectedOrgMetrics &&
                selectedOrgMetrics.pricebookLicenseMetricsList.map((metric, index) => (
                  <Grid key={metric.pricebookId}>
                    <MemberPaysMetricsCard licenseMetric={metric} />
                    {index !== selectedOrgMetrics.pricebookLicenseMetricsList.length && (
                      <Spacer height='xxs' />
                    )}
                  </Grid>
                ))}
            </Column>
          )}
        </CardContent>
      </Card>
      {selectedPricebook && (
        <PurchaseLicensesDrawer
          open={purchaseDrawerOpen}
          setOpen={setPurchaseDrawerOpen}
          pricebook={selectedPricebook}
        />
      )}
    </>
  );
};

export default AtAGlance;
