import { Link, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import Column from '../../components/Column';

interface ContractFooterProps {
  meteredPayment: boolean;
  autoPay: boolean | undefined;
  orgPays: boolean;
  prePaid: boolean;
  invoiceDate: string;
  tosLink: string | undefined;
}

const ContractFooter: React.FC<ContractFooterProps> = ({
  meteredPayment,
  autoPay,
  orgPays,
  prePaid,
  invoiceDate,
  tosLink,
}) => {
  const theme = useTheme();
  const monthNames = useMemo(() => {
    const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
    return Array.from({ length: 12 }, (_, i) => formatter.format(new Date(2000, i, 1)));
  }, []);

  const formatInvoiceDate = (date: string): string => {
    const firstWord = date.split(' ')[0];

    if (monthNames.includes(firstWord)) {
      return `on ${date}`;
    } else if (date.toLowerCase() === 'no invoice date') {
      return 'on a regular basis';
    }
    return `on the ${date.toLowerCase()}`;
  };

  const invoiceMessage =
    orgPays &&
    (prePaid ? !autoPay : true) &&
    (() => {
      const formattedDate = formatInvoiceDate(invoiceDate);
      const baseMessage = `An invoice will be ${autoPay ? 'generated' : 'sent'}`;

      return `${baseMessage} ${formattedDate} for any new or renewing licenses${
        !autoPay ? ' and will be due upon receipt.' : '.'
      }${
        meteredPayment && autoPay
          ? ' It will be automatically paid by the specified default payment.'
          : ''
      }`;
    })();

  const additionalDetails = (
    <>
      {' '}
      Additional details are available in our{' '}
      <Link href={tosLink} target='_blank'>
        Terms of Service
      </Link>
      {orgPays && (
        <>
          {' and '}
          <Link href='/cancellation-policy' target='_blank'>
            Cancellation and Refund Policy
          </Link>
        </>
      )}
      .
    </>
  );

  return (
    <Column style={{ flex: 1, width: '70%', alignSelf: 'center' }}>
      <Column style={{ flex: 1, width: '100%' }}>
        <Typography variant='p14' color='info.main'>
          <span style={{ color: theme.palette.secondary.main }}>* </span>
          {invoiceMessage}
          {additionalDetails}
        </Typography>
      </Column>
    </Column>
  );
};

export default ContractFooter;
