import React, { useContext, useState } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import Tippy from '@tippyjs/react';
import '../../themes/tippy-rounded.css';
import ClickableList from '../../components/ClickableList';
import { UserData } from '../../models/UserData';
import _ from 'lodash';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { UserApi } from '../../api/apis/UserApi';
import { useService } from '@aesop-fables/containr-react';
import { ApiKeys } from '../../api/apis/ApiKeys';

interface ChangeBillingAdminProps {
  admins: UserData[];
  currentUser: UserData | undefined;
  primaryBillingAdmin: UserData | undefined;
}

const ChangeBillingAdmin: React.FC<ChangeBillingAdminProps> = ({
  admins,
  currentUser,
  primaryBillingAdmin,
}) => {
  const { setToast } = useContext(ToastbarContext);
  const userApi = useService<UserApi>(ApiKeys.User);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClick = async (userId: number): Promise<boolean> => {
    try {
      await userApi.changePrimaryBillingUser(userId);
      const updatedAdmin = admins.find(admin => admin.id === userId);
      if (updatedAdmin) {
        setIsVisible(false);
        setToast(
          new Toast({
            message: 'Billing admin updated!',
            severity: 'success',
            open: true,
            autoHideDuration: 2000,
          }),
        );
        return true;
      }
      throw new Error('Admin not found');
    } catch (error) {
      setIsVisible(false);
      setToast(
        new Toast({
          message: 'Failed to update billing admin',
          severity: 'error',
          open: true,
          autoHideDuration: 2000,
        }),
      );
      return false;
    }
  };

  if (!primaryBillingAdmin) return null;

  return (
    <Grid container>
      <Typography variant='p16' color='secondary.main'>
        All billing communications are sent to {primaryBillingAdmin.email}.&nbsp;&nbsp;
      </Typography>
      {admins.length > 1 && (
        <Tippy
          content={
            <ChangeBillingContent
              admins={admins}
              primaryBillingAdmin={primaryBillingAdmin}
              currentUser={currentUser}
              handleClick={handleClick}
            />
          }
          placement='bottom'
          arrow={false}
          trigger='click'
          interactive={true}
          visible={isVisible}
          onClickOutside={() => setIsVisible(false)}
          theme='tippy-rounded'>
          <Link onClick={() => setIsVisible(true)} variant='p16'>
            Change billing admin
          </Link>
        </Tippy>
      )}
    </Grid>
  );
};

export default ChangeBillingAdmin;
interface ChangeBillingContentProps {
  admins: UserData[];
  primaryBillingAdmin: UserData;
  currentUser: UserData | undefined;
  handleClick: (val: any) => Promise<boolean>;
}

const ChangeBillingContent: React.FC<ChangeBillingContentProps> = ({
  admins,
  primaryBillingAdmin,
  currentUser,
  handleClick,
}) => {
  return (
    <Grid container>
      <Typography variant='p18SemiBold' color='secondary.dark'>
        Change billing admin
      </Typography>
      <ClickableList
        items={admins}
        initialSelectedId={primaryBillingAdmin.id}
        renderItem={admin =>
          _.isEqual(admin, currentUser)
            ? `${admin.firstName} ${admin.lastName} (You)`
            : `${admin.firstName} ${admin.lastName}`
        }
        getItemKey={admin => admin?.id}
        onItemClick={handleClick}
      />
    </Grid>
  );
};
