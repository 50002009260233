/* eslint-disable react-hooks/exhaustive-deps */
import {
  MutationStatus,
  MutatorState,
  useAppStorage,
  useMutation,
  useObservable,
} from '@aesop-fables/scrinium';
import OrgDataDisplay from '../organization/OrgDataDisplay';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { useService } from '@aesop-fables/containr-react';
import GroupDataDisplay from '../group/GroupDataDisplay';
import TeamDataDisplay from '../team/TeamDataDisplay';
import { SelectOrganization, UnselectOrganization } from '../../data/organization/mutations';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLoading } from '../../hooks/useLoading';
import { SelectGroup, UnselectGroup } from '../../data/group/mutations';
import { SelectTeam, UnselectTeam, SaveTeamData } from '../../data/team/mutations';
import { ToastbarContext } from '../../App';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { Toast } from '../../models/Toast';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { GroupCompartments, groupStorageKey } from '../../data/group';
import { TeamCompartments, teamStorageKey } from '../../data/team';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { GroupData } from '../../models/GroupData';
import { TeamData } from '../../models/TeamData';
import { OrganizationData } from '../../models/OrganizationData';
import { updateCrumbs } from '../../components/DrillDownBreadcrumbs';

const AdminDashboard = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const [orgStatus, setOrgStatus] = useState<string | undefined>(undefined);

  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const tier = useObservable(drillDownWizard.tier$);

  const selectGroup = useMutation(new SelectGroup());
  const selectTeam = useMutation(new SelectTeam());
  const selectOrganization = useMutation(new SelectOrganization());

  const unselectGroup = useMutation(new UnselectGroup());
  const unselectTeam = useMutation(new UnselectTeam());
  const unselectOrganization = useMutation(new UnselectOrganization());

  const saveTeamData = useMutation(new SaveTeamData());

  const groupDataCache = appStorage.retrieve<GroupCompartments>(groupStorageKey);
  const teamDataCache = appStorage.retrieve<TeamCompartments>(teamStorageKey);
  const orgDataCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const groupData = useObservable(groupDataCache.observe$<GroupData>('group'));
  const teamData = useObservable(teamDataCache.observe$<TeamData>('team'));
  const orgData = useObservable(orgDataCache.observe$<OrganizationData>('organization'));
  const [isBack, setIsBack] = useState(false);

  useEffect(() => {
    window.onpopstate = () => {
      setIsBack(true);
    };

    const handleClick = () => {
      setIsBack(false);
    };

    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  });

  useEffect(() => {
    if (
      isBack &&
      (location.pathname.includes('/choose-group') || tier === AdminTierTypeEnum.Group)
    ) {
      updateCrumbs({ groupData, unselectGroup });
    } else if (
      isBack &&
      (location.pathname.includes('/teams') || tier === AdminTierTypeEnum.Team)
    ) {
      updateCrumbs({ teamData, unselectTeam });
    } else if (
      isBack &&
      (location.pathname.includes('/organizations') || tier === AdminTierTypeEnum.Organization)
    ) {
      updateCrumbs({ orgData, unselectOrganization });
    }
  }, [
    groupData,
    isBack,
    location.pathname,
    orgData,
    teamData,
    tier,
    unselectGroup,
    unselectOrganization,
    unselectTeam,
  ]);

  useEffect(() => {
    if (selectGroup.status === MutationStatus.Complete) {
      navigate('/controller/teams');
      setLoading(false);
    }
  }, [selectGroup.status]);

  useEffect(() => {
    if (selectTeam.status === MutationStatus.Complete) {
      navigate('/controller/organizations');
      setLoading(false);
    }
  }, [selectTeam.status]);

  useEffect(() => {
    if (!orgStatus) {
      return;
    }
    if (orgStatus === 'complete') {
      if (selectOrganization.status === MutationStatus.Complete) {
        navigate('/');
        setLoading(false);
        setOrgStatus(undefined);
        return;
      }
    }
    if (orgStatus === 'incomplete') {
      if (selectOrganization.error) {
        setToast(
          new Toast({
            message:
              'Could not continue. If this error persists please remove this organization and start over.',
            severity: 'error',
            open: true,
          }),
        );
        setLoading(false);
        setOrgStatus(undefined);
      } else if (selectOrganization.status === MutationStatus.Complete) {
        signUpWizard.init();
        const step = signUpWizard.resumeOnboarding(orgSignUpState?.lastCompletedStep ?? '');
        navigate(step?.route ?? '');
        setLoading(false);
        setOrgStatus(undefined);
      }
    }
  }, [selectOrganization.status, signUpDataCache]);

  useEffect(() => {
    if (saveTeamData?.error?.message.includes('400')) {
      setToast(
        new Toast({
          message: 'Team name is already taken. Please use a unique name.',
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      setLoading(false);
    } else if (saveTeamData?.status === MutationStatus.Complete) {
      setToast(
        new Toast({
          message: 'Team created!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
      navigate('/controller/organizations');
      setLoading(false);
    }
  }, [saveTeamData.status, setToast]);

  return (
    <>
      {(tier === AdminTierTypeEnum.Organization && (
        <OrgDataDisplay select={selectOrganization} setOrgStatus={setOrgStatus} />
      )) ||
        (tier === AdminTierTypeEnum.Team && (
          <TeamDataDisplay select={selectTeam} saveData={saveTeamData} />
        )) ||
        (tier === AdminTierTypeEnum.Group && <GroupDataDisplay select={selectGroup} />)}
    </>
  );
};

export interface DataDisplayProps {
  select: MutatorState<number>;
  saveData?: MutatorState<any>;
}

export default AdminDashboard;
