import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { TabPanel, Tabs } from '../../components/Tabs';
import { Page, Row } from '../../components';
import ReportUserAccounts from './ReportUserAccounts/ReportUserAccounts';
import ReportUserData from './ReportUserData/ReportUserData';
import ReportUserProgress from './ReportUserProgress/ReportUserProgress';
import ReportsHeader from './ReportHeader';
import dayjs, { Dayjs } from 'dayjs';
import { useLoading } from '../../hooks/useLoading';
import { useService } from '@aesop-fables/containr-react';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { AdminTierTypeEnum, DrillDownWizard, drillDownWizardKey } from '../../services/drillDown';
import { AnyReportType, OrgReportData } from '../../models/ReportData';
import { hasProgressData } from '../../helpers/reportUtils';
import { useReportData } from '../../hooks/useReportData';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { getReportCompartmentKeys, reloadReportCompartments } from '../../data/report';
import { RegenerateOrgReport } from '../../data/report/mutations';
import { Grid, Typography } from '@mui/material';
import { formatTimestamp } from '../../helpers/utilityFunctions';

const Reports: React.FC = () => {
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);
  const appStorage = useAppStorage();

  const drillDownWizard = useService<DrillDownWizard>(drillDownWizardKey);
  const tier = useObservable(drillDownWizard.tier$);
  const { getReportData, getSummedReportData, getEarliestReportDate } = useReportData();
  const regenerateOrgReport = useMutation(new RegenerateOrgReport());
  const compartmentKeys = getReportCompartmentKeys(tier);

  const [activeTab, setActiveTab] = useState(0);
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [report, setReport] = useState<AnyReportType | null>(null);
  const [summedReport, setSummedReport] = useState<AnyReportType | null>(null);
  const [earliestDate, setEarliestDate] = useState<Dayjs | null>(null);
  const rootLevel = tier === AdminTierTypeEnum.Root;
  const showProgress = useMemo(() => {
    return !report ? null : hasProgressData(report);
  }, [report]);
  const today = dayjs().endOf('day');
  const isCurrentDate = selectedDate?.isSame(dayjs(), 'day');

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const onRefresh = async () => {
    setLoading(true);
    try {
      if (rootLevel) {
        await regenerateOrgReport.action();
        fetchReports();
      }
      await reloadReportCompartments(appStorage, compartmentKeys);
      await new Promise(resolve => setTimeout(resolve, 100)); // artificial delay for toast
      setToast(
        new Toast({
          message: 'Latest data refreshed!',
          severity: 'success',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } catch (error) {
      console.error('Error on refresh:', error);
      await new Promise(resolve => setTimeout(resolve, 100));
      setToast(
        new Toast({
          message: `Sorry! We couldn't update your data.`,
          severity: 'error',
          open: true,
          autoHideDuration: 3000,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const tabLabels = useMemo(() => {
    const labels = ['User accounts', 'User data'];
    if (showProgress) {
      labels.push('User progress');
    }
    return labels;
  }, [showProgress]);

  const fetchReports = useCallback(async () => {
    if (!selectedDate) return;

    const fetchedReport = await getReportData(selectedDate, tier);
    setReport(fetchedReport);

    if (tier === AdminTierTypeEnum.Organization || tier === AdminTierTypeEnum.Team) {
      const fetchedSummedReport = await getSummedReportData(selectedDate, tier);
      setSummedReport(fetchedSummedReport);
    } else {
      setSummedReport(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tier, selectedDate]);

  useEffect(() => {
    const reloadReport = async () => {
      try {
        setLoading(true);
        await reloadReportCompartments(appStorage, compartmentKeys, selectedDate ?? dayjs());
        await fetchReports();
      } catch (error) {
        console.error('Failed to reload the report:', error);
      } finally {
        setLoading(false);
      }
    };
    reloadReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchReports]);

  useEffect(() => {
    const fetchEarliestDate = async () => {
      const date = await getEarliestReportDate();
      setEarliestDate(date);
    };
    fetchEarliestDate();
  }, [getEarliestReportDate]);

  return (
    <Page>
      <Grid display='flex' flex={1} alignItems='flex-end' mb={2}>
        <Typography variant='p40' color='secondary.main'>
          Reports
        </Typography>
        {tier === AdminTierTypeEnum.Root && report && isCurrentDate && (
          <Typography
            display='flex'
            flex={1}
            mb={1}
            justifyContent='flex-end'
            variant='p14'
            color='secondary.main'>
            data last refreshed at{' '}
            {formatTimestamp((report as OrgReportData).createdDate.toString())}
          </Typography>
        )}
      </Grid>
      <Row style={{ justifyContent: 'space-between' }}>
        <Tabs value={activeTab} className='bordered' labels={tabLabels} onChange={handleChange} />
        <ReportsHeader
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          onRefresh={onRefresh}
          earliestDate={earliestDate}
          tier={tier}
          isCurrentDate={isCurrentDate}
          today={today}
        />
      </Row>
      <TabPanel value={activeTab} index={0}>
        <ReportUserAccounts report={report} tier={tier} summedReport={summedReport} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ReportUserData report={report} summedReport={summedReport} />
      </TabPanel>
      {showProgress && (
        <TabPanel value={activeTab} index={2}>
          <ReportUserProgress report={report} summedReport={summedReport} />
        </TabPanel>
      )}
    </Page>
  );
};

export default Reports;
