import { useCallback } from 'react';
import { AdminStatusEnum, UserData } from '../models/UserData';

export const restrictedAdminDomains = ['@guidedchoice', '@3nickels'];

export interface FilteredAdminData extends Omit<UserData, 'role'> {
  roles: string[];
}

export const useAdminFilters = () => {
  const checkAdminAccess = useCallback((user: UserData | undefined) => {
    return user && restrictedAdminDomains.some(domain => user.email?.includes(domain));
  }, []);

  const getRoles = (role: string | string[] | undefined): string[] => {
    if (!role) return [];
    return Array.isArray(role) ? role : [role];
  };

  const filterAdmins = useCallback(
    (admins: UserData[] | undefined, currentUser: UserData | undefined): FilteredAdminData[] => {
      if (!admins) return [];

      const canViewRestrictedAdmins = checkAdminAccess(currentUser);
      const adminMap = new Map<string, FilteredAdminData>();

      admins.forEach(admin => {
        if (!admin.email || admin.status === AdminStatusEnum.Removed) return;

        const isRestrictedAdmin = restrictedAdminDomains.some(
          domain => admin.email?.includes(domain),
        );
        const isPrimaryBilling = admin.primaryBillingUser;

        if (!canViewRestrictedAdmins && isRestrictedAdmin && !isPrimaryBilling) return;

        // Combine admins with same email but different roles
        // Each admin should now have an array of roles
        const roles = getRoles(admin.role);

        if (adminMap.has(admin.email)) {
          const existingAdmin = adminMap.get(admin.email)!;
          existingAdmin.roles = [...new Set([...existingAdmin.roles, ...roles])];
          if (admin.primaryBillingUser) {
            existingAdmin.primaryBillingUser = true;
          }
          if (
            admin.statusTimestamp &&
            (!existingAdmin.statusTimestamp ||
              new Date(admin.statusTimestamp) > new Date(existingAdmin.statusTimestamp))
          ) {
            existingAdmin.statusTimestamp = admin.statusTimestamp;
          }
        } else {
          const { role, ...adminWithoutRole } = admin;
          adminMap.set(admin.email, {
            ...adminWithoutRole,
            roles: roles,
          });
        }
      });

      return Array.from(adminMap.values());
    },
    [checkAdminAccess],
  );

  return { checkAdminAccess, filterAdmins };
};
