import { Button, Card, CardContent, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../api/apis';
import { LicenseAgreementAcceptLogData } from '../../api/apis/LicenseAgreementAcceptLogApi';
import { LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { Svgs } from '../../assets/svg';
import { Page, Spacer, Row, DataTable } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import { InvalidateLicenseAgreement } from '../../data/license/mutations';
import {
  licenseAgreementAcceptLogCompartments,
  licenseAgreementAcceptLogStorageKey,
} from '../../data/licenseAgreementAcceptLog';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { ProductTypeCompartments, productTypeStorageKey } from '../../data/productType';
import { filterByPayer, formatDateMMDDYYYY, formatDollars } from '../../helpers/utilityFunctions';
import { LicenseInfo } from '../../models/OrganizationData';
import { PricebookBundle, PaymentTypeEnum } from '../../models/PricebookData';
import { Toast } from '../../models/Toast';
import { ToastbarContext } from '../../App';
import {
  LearnMoreGuidedChoiceDrawer,
  LearnMoreAdviceDrawer,
  LearnMoreThreeNickelsFreeDrawer,
  LearnMoreAdviceAndThreeNickelsUDrawer,
} from '../../sign-up/learn-more';
import PurchaseLicensesDrawer from './PurchaseLicensesDrawer';
import { SubscriptionData } from '../../api/apis/SubscriptionApi';
import { SubscriptionCompartments, subscriptionStorageKey } from '../../data/subscription';
import { GroupFeatureMapCompartments, groupFeatureMapStorageKey } from '../../data/groupFeatureMap';
import { GroupFeatureMapData } from '../../models/GroupFeatureMapData';
import UnpaidInvoiceCard from '../payment/UnpaidInvoiceCard';

const LicenseAgreement = () => {
  const headers = [
    {
      title: 'Product',
      label: 'product',
    },
    {
      title: 'Date',
      label: 'date',
    },
    {
      title: 'Signed by',
      label: 'admin',
    },
  ];
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);

  const invalidateAgreement = useMutation(new InvalidateLicenseAgreement());

  const productTypeDataCache = appStorage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const licenseAgreementAcceptLogDataCache =
    appStorage.retrieve<licenseAgreementAcceptLogCompartments>(licenseAgreementAcceptLogStorageKey);
  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const subscriptionDataCache =
    appStorage.retrieve<SubscriptionCompartments>(subscriptionStorageKey);
  const groupFeatureMapDataCache =
    appStorage.retrieve<GroupFeatureMapCompartments>(groupFeatureMapStorageKey);

  const paidBy = useObservable(productTypeDataCache.observe$<string>('paidBy'));
  const licenseAgreements =
    useObservable(licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement')) ?? [];
  const licenseAgreementAcceptLog =
    useObservable(
      licenseAgreementAcceptLogDataCache.observe$<Map<number, LicenseAgreementAcceptLogData>>(
        'licenseAgreementAcceptLog',
      ),
    ) ?? new Map<number, LicenseAgreementAcceptLogData>();
  // eslint-disable-next-line
  const pricebookOptions =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions')) ?? [];
  const pricebookSelections = useObservable(
    pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections'),
  );
  // eslint-disable-next-line
  const subscriptions =
    useObservable(subscriptionDataCache.observe$<SubscriptionData[]>('subscription')) ?? [];
  const groupFeatureMapData = useObservable(
    groupFeatureMapDataCache.observe$<GroupFeatureMapData>('groupFeatureMap'),
  );

  const pendingAgreement =
    licenseAgreements &&
    licenseAgreements?.find(
      licenseAgreement => licenseAgreement.docStatus && !licenseAgreement.hasAccepted,
    );
  const activeLicenses =
    licenseAgreements &&
    licenseAgreements.filter(license => license.hasAccepted && license.docStatus);
  const zeroActive = activeLicenses && activeLicenses.length === 0;
  const meteredPayment = activeLicenses.some(
    agreement => agreement.pricebookBundleDto.pricebook.paymentType === PaymentTypeEnum.Metered,
  );
  const [learnMoreDrawerOpen, setLearnMoreDrawerOpen] = useState<boolean>(false);
  const [expiredLearnMoreDrawerOpen, setExpiredLearnMoreDrawerOpen] = useState<boolean>(false);
  const [purchaseDrawerOpen, setPurchaseDrawerOpen] = useState<boolean>(false);
  const [packagesAvailable, setPackagesAvailable] = useState(false);
  const [failedInvoices, setFailedInvoices] = useState<SubscriptionData[]>([]);

  const getNameAndPrice = (licenseAgreement: LicenseAgreementData) => {
    const prices = licenseAgreement.pricebookBundleDto?.prices || [];
    const pricebookName = licenseAgreement.pricebookBundleDto?.pricebook.pricebookName;

    const selectedPrice = prices.find(price => price.intervalType === 'NONE') || prices[0];
    const priceInDollars = selectedPrice ? selectedPrice.price / 100 : 0;

    return priceInDollars > 0
      ? `${pricebookName} (${formatDollars(priceInDollars)})`
      : pricebookName;
  };

  useEffect(() => {
    setFailedInvoices(subscriptions.filter(subscription => subscription.paymentFailed));
  }, [subscriptions]);

  useEffect(() => {
    if (pricebookOptions) {
      const filteredPricebook = filterByPayer(pricebookOptions, paidBy);
      setPackagesAvailable(filteredPricebook.length > (pricebookSelections?.length ?? 0));
    }
  }, [paidBy, pricebookOptions, pricebookSelections?.length]);

  const getPurchaseDrawer = (row: any) => {
    const coercedRow = row as {
      id: number;
      product: string;
      date: string;
      admin: string;
      deletableRow: boolean;
    };
    const selectedPricebook =
      licenseAgreements && licenseAgreements?.find(agreement => agreement.id === coercedRow.id);

    if (selectedPricebook === undefined) {
      setToast(
        new Toast({
          message: `Error finding pricebook`,
          severity: 'error',
          open: true,
        }),
      );
      setPurchaseDrawerOpen(false);
      return <></>;
    }

    return (
      <PurchaseLicensesDrawer
        open={purchaseDrawerOpen}
        setOpen={setPurchaseDrawerOpen}
        pricebook={selectedPricebook.pricebookBundleDto}
      />
    );
  };

  const addPackage = () => {
    navigate('/addPackage', { state: { origin: 'license' } });
  };

  const findSigningAdmin = (docId: number): string => {
    const { user } = licenseAgreementAcceptLog.get(docId) ?? {};
    return `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  };

  const viewLicense = async (row: any) => {
    const docId = (row as LicenseInfo).id;
    const licenseAgreement = licenseAgreements && licenseAgreements?.find(doc => doc.id === docId);
    if (!licenseAgreement) {
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Error locating document. Please contact us if the problem persists.',
        }),
      );
      return;
    }
    const docUri = API_URL + 'license-agreement/doc?docId=' + licenseAgreement.id;
    window.open(`${docUri}`, '_blank');
  };

  const onDelete = async (row: any) => {
    try {
      const licenseAgreement =
        licenseAgreements &&
        licenseAgreements?.find(doc => doc.id === (row as LicenseAgreementData).id);
      if (licenseAgreement) {
        await invalidateAgreement.action(licenseAgreement);
      } else {
        throw new Error(
          'Could not find license agreement with id: ' + (row as LicenseAgreementData).id,
        );
      }
    } catch (err) {
      setToast(
        new Toast({
          message: `${(row as LicenseInfo).product} could not removed`,
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const getLearnMoreDrawer = (entry: any) => {
    const selectedPricebook =
      licenseAgreements && licenseAgreements?.find(agreement => agreement.id === entry.id);
    const expiredSelectedPricebook =
      licenseAgreements && licenseAgreements?.find(agreement => agreement.id === entry.id);
    if (selectedPricebook === undefined && expiredSelectedPricebook === undefined) {
      setToast(
        new Toast({
          message: `Error finding pricebook`,
          severity: 'error',
          open: true,
        }),
      );
      return <></>;
    }

    // active
    if (entry.deletableRow) {
      if (
        selectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes(
          'GuidedChoice Advisory Service',
        )
      ) {
        return (
          <LearnMoreGuidedChoiceDrawer
            drawerOpen={learnMoreDrawerOpen}
            setDrawerOpen={setLearnMoreDrawerOpen}
            pricebookBundle={selectedPricebook.pricebookBundleDto}
          />
        );
      }
      if (
        selectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes('3Nickels Advice')
      ) {
        return (
          <LearnMoreAdviceDrawer
            drawerOpen={learnMoreDrawerOpen}
            setDrawerOpen={setLearnMoreDrawerOpen}
            pricebookBundle={selectedPricebook.pricebookBundleDto}
          />
        );
      }
      if (selectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes('3Nickels Free')) {
        return (
          <LearnMoreThreeNickelsFreeDrawer
            drawerOpen={learnMoreDrawerOpen}
            setDrawerOpen={setLearnMoreDrawerOpen}
            pricebookBundle={selectedPricebook.pricebookBundleDto}
          />
        );
      }
      if (
        selectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes('Advice + 3NickelsU')
      ) {
        return (
          <LearnMoreAdviceAndThreeNickelsUDrawer
            drawerOpen={learnMoreDrawerOpen}
            setDrawerOpen={setLearnMoreDrawerOpen}
            pricebookBundle={selectedPricebook.pricebookBundleDto}
          />
        );
      }
    }

    // expired
    if (!entry.deletableRow) {
      if (
        expiredSelectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes(
          'GuidedChoice Advisory Service',
        )
      ) {
        return (
          <LearnMoreGuidedChoiceDrawer
            drawerOpen={expiredLearnMoreDrawerOpen}
            setDrawerOpen={setExpiredLearnMoreDrawerOpen}
            pricebookBundle={expiredSelectedPricebook.pricebookBundleDto}
          />
        );
      }
      if (
        expiredSelectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes(
          '3Nickels Advice',
        )
      ) {
        return (
          <LearnMoreAdviceDrawer
            drawerOpen={expiredLearnMoreDrawerOpen}
            setDrawerOpen={setExpiredLearnMoreDrawerOpen}
            pricebookBundle={expiredSelectedPricebook.pricebookBundleDto}
          />
        );
      }
      if (
        expiredSelectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes(
          '3Nickels Free',
        )
      ) {
        return (
          <LearnMoreThreeNickelsFreeDrawer
            drawerOpen={expiredLearnMoreDrawerOpen}
            setDrawerOpen={setExpiredLearnMoreDrawerOpen}
            pricebookBundle={expiredSelectedPricebook.pricebookBundleDto}
          />
        );
      }
      if (
        expiredSelectedPricebook?.pricebookBundleDto.pricebook.pricebookName.includes(
          'Advice + 3NickelsU',
        )
      ) {
        return (
          <LearnMoreAdviceAndThreeNickelsUDrawer
            drawerOpen={expiredLearnMoreDrawerOpen}
            setDrawerOpen={setExpiredLearnMoreDrawerOpen}
            pricebookBundle={expiredSelectedPricebook.pricebookBundleDto}
          />
        );
      }
    }
    setToast(
      new Toast({
        message: `Error finding pricebook match`,
        severity: 'error',
        open: true,
      }),
    );
    return <></>;
  };

  return (
    <Page title='License agreement'>
      <Spacer height='xs' />
      {/* {stripeSessionStatus && (
        <>
          <IncompleteCheckoutSessionCard stripeSession={stripeSessionStatus} />
          <Spacer height='xs' />
        </>
      )} */}
      {failedInvoices.map(invoice => (
        <>
          <UnpaidInvoiceCard subscription={invoice} />
          <Spacer height='xs' />
        </>
      ))}
      <Row style={{ justifyContent: 'space-between' }}>
        <Typography variant='subtitle1' color='secondary.main'>
          Active license agreement(s)
        </Typography>
        {(zeroActive || paidBy !== 'ORG') &&
          packagesAvailable &&
          groupFeatureMapData?.canAddPackage && (
            <Button className='lg' onClick={addPackage} color='primary'>
              Add a package
            </Button>
          )}
      </Row>
      <Spacer height='xs' />
      {!packagesAvailable && (
        <>
          <Card variant='ghost' color='primary'>
            <CardContent className='pad20'>
              <Row style={{ justifyContent: 'center' }}>
                <Typography variant='h3' color='secondary.main'>
                  You’ve added all available packages!
                </Typography>
              </Row>
            </CardContent>
          </Card>
          <Spacer height='sm' />
        </>
      )}
      {!zeroActive &&
        (paidBy === 'ORG' ? (
          <Row style={{ alignItems: 'center' }}>
            <Svgs.IconOrgPaysLarge />
            <Spacer width='xxs' />
            <Typography color='secondary.main' variant='p20SemiBold'>
              Organization pays
            </Typography>
          </Row>
        ) : (
          <Row style={{ alignItems: 'center' }}>
            <Svgs.IconMemberPaysLarge />
            <Spacer width='xxs' />
            <Typography color='secondary.main' variant='p20SemiBold'>
              Member pays
            </Typography>
          </Row>
        ))}
      <Spacer height='xs' />
      <DataTable
        data={licenseAgreements
          .filter(licenseAgreements => licenseAgreements.docStatus && licenseAgreements.hasAccepted)
          .map(licenseAgreement => {
            return {
              id: licenseAgreement.id,
              product: getNameAndPrice(licenseAgreement),
              date: formatDateMMDDYYYY(licenseAgreement.docDate),
              admin: findSigningAdmin(licenseAgreement.id),
              deletableRow: groupFeatureMapData?.organizationAllowInvalidateLicenseAgreement,
            };
          })}
        headers={headers}
        sortBy='product'
        onButton1Click={viewLicense}
        button1Text={'View agreement'}
        onButton2Click={paidBy === 'ORG' ? setPurchaseDrawerOpen : undefined}
        button2Text={paidBy === 'ORG' && !meteredPayment ? 'Edit licenses' : undefined}
        button2Disabled={typeof pendingAgreement !== 'undefined'}
        onDelete={onDelete}
        type='licenseAgreement'
        emptyStateText={`You don't have any active license agreements.`}
        action={getLearnMoreDrawer}
        action2={setLearnMoreDrawerOpen}
        action3={getPurchaseDrawer}
      />
      <DataTable
        title='Expired license agreement(s)'
        data={licenseAgreements
          .filter(
            licenseAgreements => !licenseAgreements.docStatus && licenseAgreements.hasAccepted,
          )
          .map(licenseAgreement => {
            return {
              id: licenseAgreement.id,
              product: getNameAndPrice(licenseAgreement),
              date: formatDateMMDDYYYY(licenseAgreement.docDate),
              admin: findSigningAdmin(licenseAgreement.id),
              deletableRow: false,
            };
          })}
        type='licenseAgreement'
        headers={headers}
        sortBy='product'
        onButton1Click={viewLicense}
        button1Text={'View agreement'}
        emptyStateText={`You don't have any expired license agreements.`}
        action={getLearnMoreDrawer}
        action2={setExpiredLearnMoreDrawerOpen}
      />
    </Page>
  );
};

export default LicenseAgreement;
