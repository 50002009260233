import React from 'react';
import { HelpOutline } from '@mui/icons-material';
import { Grid, Typography, useTheme } from '@mui/material';
import Tippy from '@tippyjs/react';
import { Spacer } from '../../../components';
import { Bold } from '../../../components/Bold';
import BulletedList from '../../../components/BulletedList';
import { HelpTitle } from '../../../components/HelpText';
import { ReportProps } from '../../../models/ReportData';
import { formatNumber } from '../../../helpers/utilityFunctions';
import { UserAccountsTableData } from '../../../helpers/reportUtils';
import { AdminTierTypeEnum, getNextHigherTier } from '../../../services/drillDown';
import { SemiBold } from '../../../components/SemiBold';

interface TotalUserAccountsProps extends ReportProps {
  isEmpty?: boolean;
  showHelp?: boolean;
  tier?: AdminTierTypeEnum;
  tableData?: UserAccountsTableData;
}

const TotalUserAccounts: React.FC<TotalUserAccountsProps> = ({
  report,
  isEmpty,
  showHelp,
  tier,
  tableData,
}) => {
  const theme = useTheme();

  if (isEmpty) return null;

  return (
    <Grid item flexDirection='column' pr={5.5}>
      <Typography variant='p30' color='secondary.main'>
        <Bold>{formatNumber(report?.totalAccounts)}</Bold>
      </Typography>
      <Grid container flexDirection='row'>
        <Typography variant='p16' color='secondary.main'>
          Total user accounts
        </Typography>
        <Spacer width='xxxs' />
        {showHelp && (
          <Tippy
            placement='right'
            content={<TotalUsersHelpText tier={tier} tableData={tableData} />}
            theme='tippy-rounded'>
            <HelpOutline style={{ color: theme.palette.info.main }} />
          </Tippy>
        )}
      </Grid>
    </Grid>
  );
};

export default TotalUserAccounts;

interface TotalUsersHelpTextProps {
  tier?: AdminTierTypeEnum;
  tableData?: UserAccountsTableData;
}

const TotalUsersHelpText: React.FC<TotalUsersHelpTextProps> = ({ tier, tableData }) => {
  const nextTier = tier ? getNextHigherTier(tier) : AdminTierTypeEnum.Organization;

  const descriptions: Record<string, string> = {
    'Total user accounts': `all accounts connected to this ${nextTier?.toLowerCase()}`,
    'Active': 'accounts with an active license',
    'Invited': 'emails that have been added but do not have an account',
    'Undecided': 'users who created an account but have not chosen a product',
    'Abandoned': 'users who started creating an account but did not finish',
    'Ineligible': `users who have been removed from their ${nextTier?.toLowerCase()}`,
    'In trial period': 'users who have started a free trial from the mobile app',
    'Unsubscribed': 'users who no longer have an active license',
  };

  const listItems = Object.entries(descriptions)
    .filter(([key]) => tableData || key === 'Total user accounts') // if not displaying table, only show "Total user accounts" bullet
    .map(([key, value]) => (
      <Typography key={key} color='secondary.dark'>
        <SemiBold>{key}</SemiBold>: {value}
      </Typography>
    ));

  return (
    <Grid>
      <HelpTitle>User Accounts</HelpTitle>
      <Spacer height='xxs' />
      <BulletedList children={listItems} />
      <Spacer height='xxs' />
    </Grid>
  );
};
