import { Grid } from '@mui/material';
import { Spacer } from '../../../components';
import { HelpTitle, HelpBody } from '../../../components/HelpText';
import { AnyReportType } from '../../../models/ReportData';
import { ProgressCalculator } from './ReportProgressTracker';
import { getPricebookReportsByType, sumPropertyInArray } from '../../../helpers/reportUtils';
import { ProductTypeEnum } from '../../../models/PricebookData';

export const progressTracker3NU: ProgressCalculator = (report: AnyReportType) => {
  const reports3nu = getPricebookReportsByType(report, ProductTypeEnum.Nickels);

  return {
    title: '3NickelsU progress',
    totalUsers: report?.totalNickelsU,
    notStarted: sumPropertyInArray(reports3nu, 'notStarted3nuCount'),
    inProgress: [
      {
        label: 'Less than 25% complete',
        value: sumPropertyInArray(reports3nu, 'zeroToTwentyFour3nuCount'),
      },
      {
        label: '25-75% complete',
        value: sumPropertyInArray(reports3nu, 'twentyFiveToSeventyFive3nuCount'),
      },
      {
        label: '76-99% complete',
        value: sumPropertyInArray(reports3nu, 'seventySixToNinetyNine3nuCount'),
      },
    ],
    completed: sumPropertyInArray(reports3nu, 'nuCompletedCount'),
    avgProgress: sumPropertyInArray(reports3nu, 'average3nuProgress'),
    helpText: '3NickelsU-eligible users',
    helpContent: <HelpText3nu />,
  };
};

const HelpText3nu = () => {
  return (
    <Grid>
      <HelpTitle>3NickelsU-eligible users</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        Count of users connected to this organization who have access to 3NickelsU content.
      </HelpBody>
    </Grid>
  );
};

export const AvgProgress3nuHelp = () => {
  return (
    <Grid>
      <HelpTitle>Average progress</HelpTitle>
      <Spacer height='xxs' />
      <HelpBody>
        The average progress through 3NickelsU content for all users that have started at least one
        video.
      </HelpBody>
    </Grid>
  );
};
