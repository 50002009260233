import { Grid, Typography, Card, CardContent } from '@mui/material';
import { Spacer, Row } from '../../../components';
import { formatTwoDecimalPlaces } from '../../../helpers/utilityFunctions';

interface InvoiceDetailsProps {
  invoiceTotal?: number;
}

const OutstandingInvoice: React.FC<InvoiceDetailsProps> = ({ invoiceTotal }) => {
  return (
    <Grid container flexDirection='column' alignItems='center'>
      <Typography variant='p18SemiBold' color='secondary.dark'>
        Outstanding invoice total
      </Typography>
      <Spacer height='xxs' />
      <Card variant='innerCard' style={{ minWidth: '200px', padding: '0px 10px 0px 10px' }}>
        <CardContent>
          <Row style={{ justifyContent: 'center' }}>
            <Typography color='info.main' style={{ display: 'inline' }} variant='subtitle1'>
              $
            </Typography>
            <Typography color='secondary.dark' style={{ display: 'inline' }} variant='subtitle1'>
              {formatTwoDecimalPlaces((invoiceTotal ?? 0) / 100.0)}
            </Typography>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            <Typography variant='p12SemiBold' color='secondary.main'>
              due today
            </Typography>
          </Row>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default OutstandingInvoice;
