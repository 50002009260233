import { Typography, Button, Card, CardContent } from '@mui/material';
import { Column, RightDrawer, Row, Spacer } from '../../components';
import { logo3nUni } from '../../assets/png';
import { Svgs } from '../../assets/svg';
import {
  PricebookBundle,
  PaymentTypeEnum,
  ProductTypeEnum,
  IntervalTypeEnum,
} from '../../models/PricebookData';

export interface LearnMoreDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  pricebookBundle: PricebookBundle;
}

export const LearnMoreAdviceAndThreeNickelsUDrawer: React.FC<LearnMoreDrawerProps> = ({
  drawerOpen,
  setDrawerOpen,
  pricebookBundle,
}) => {
  const subscriptionMonths = pricebookBundle.pricebook.metadata.subscriptionMonths ?? 'two';

  return (
    <RightDrawer open={drawerOpen} setOpen={setDrawerOpen}>
      <Row>
        <Column style={{ width: '90%' }}>
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            {pricebookBundle.pricebook.paidBy === 'USER' ? (
              <Svgs.IconMemberPaysLarge />
            ) : (
              <Svgs.IconOrgPaysLarge />
            )}
            <Spacer width='xxs' />
            <Typography color='secondary' variant='p12SemiBold'>
              {pricebookBundle.pricebook.paidBy === 'USER' ? 'Member pays' : 'Organization pays'}
            </Typography>
          </Row>

          <Spacer height='xxs' />
          <Typography color='secondary' variant='p30Light'>
            {pricebookBundle.pricebook.pricebookName}
          </Typography>
          <Spacer height='xxs' />
        </Column>
        <img src={logo3nUni} alt='logo' style={{ height: '40px' }} />
      </Row>

      <PackageCard pricebookBundle={pricebookBundle} />
      <Spacer height='xs' />

      <Typography color='secondary' variant='p18Bold'>
        Description
      </Typography>
      <Spacer height='xxs' />
      <Row>
        <Column style={{ width: '100%' }}>
          <Typography color='info.main' variant='body1'>
            No matter where you’re at in your journey, we’re all about setting you up for success.
            3NickelsU is a program that teaches you how to get on the path to financial freedom.
            Just a few hours can set you up for life!
          </Typography>
        </Column>
        <Svgs.ArtStudyMaterials />
      </Row>
      <Spacer height='xs' />
      <Typography color='secondary' variant='p18Bold'>
        At a glance...
      </Typography>
      <Column style={{ padding: '0px 10px' }}>
        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.Bullet3nUniCoach />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            Personalized one-on-one <strong>coaching</strong>
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.Bullet3nUniLessons />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            30 quick <strong>lessons</strong>
          </Typography>
        </Row>

        <Spacer height='xs' />
        <Row style={{ alignItems: 'center' }}>
          <Svgs.Bullet3nUniQA />
          <Spacer width='xs' />
          <Typography sx={{ maxWidth: '80%' }} variant='body1' color='info.main'>
            Live <strong>Q&amp;A</strong>
          </Typography>
        </Row>
      </Column>

      <Spacer height='sm' />
      <Typography color='secondary' variant='p18SemiBold'>
        And you have access to 3Nickels Advice!
      </Typography>
      <Row>
        <Svgs.ArtPieChart />
        <Column style={{ width: '80%', marginLeft: '20px' }}>
          <Spacer height='xs' />
          <Typography color='info.main' variant='body1'>
            For {subscriptionMonths} months, you have access to the Advice tier of 3Nickels.
            Experience The Financial Advisor In Your Pocket<sup>&#174;</sup>!
          </Typography>
          <Spacer height='xs' />
          <Typography color='info.main' variant='body1'>
            Connect all of your finances in one place and let us do the heavy lifting.
          </Typography>
        </Column>
      </Row>

      <Spacer height='sm' />
      <Row style={{ justifyContent: 'space-between' }}>
        <Button
          onClick={() => setDrawerOpen(false)}
          className='sm'
          variant='outlined'
          color='secondary'>
          Close
        </Button>
      </Row>
      <Spacer height='md' />
    </RightDrawer>
  );
};

interface PackageCardProps {
  pricebookBundle: PricebookBundle;
}

export const PackageCard: React.FC<PackageCardProps> = ({ pricebookBundle }) => {
  const selectedPrice =
    pricebookBundle.prices.find(price => price.intervalType === IntervalTypeEnum.None) ||
    pricebookBundle.prices[0];
  const price = selectedPrice ? selectedPrice.price / 100 : 0;
  const orgPays = pricebookBundle.pricebook.paidBy === 'ORG';
  const guidedChoice = pricebookBundle.pricebook.productType === ProductTypeEnum.GuidedChoice;

  const promotion = pricebookBundle.prices.find(price => price.introductionPeriodInDays > 1);
  const introPeriodInMonths = Math.floor((promotion?.introductionPeriodInDays ?? 0) / 30);
  const freeTrial30Day = pricebookBundle.pricebook.metadata?.freeTrial30Day === 'true';
  const meteredPayment = pricebookBundle.pricebook.paymentType === PaymentTypeEnum.Metered;
  return (
    <>
      <Card variant='ghost' color='primary'>
        <CardContent className='pad20'>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <img
              src={pricebookBundle.pricebook.artUrl}
              alt='Product Art'
              style={{ height: '80px' }}
            />
            <Column style={{ width: '40%', margin: '10px' }}>
              {pricebookBundle.pricebook.description.map((bullet, index) => (
                <Row key={index}>
                  <Typography style={{ marginRight: '20px' }}>•</Typography>
                  <Typography variant='body1' color={guidedChoice ? 'secondary.dark' : 'secondary'}>
                    {bullet}
                  </Typography>
                </Row>
              ))}
            </Column>
            <Card style={{ alignSelf: 'center', minWidth: '100px' }} variant='innerCard'>
              <CardContent className='centered-inner'>
                <Column style={{ alignItems: 'center' }}>
                  <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {freeTrial30Day ? (
                      <Typography
                        variant={guidedChoice ? 'p20SemiBold' : 'p20SemiBold'}
                        color='secondary.dark'>
                        30 days free
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant={guidedChoice ? 'p20SemiBold' : 'p20SemiBold'}
                          color={guidedChoice ? 'info.main' : 'secondary'}>
                          $
                        </Typography>
                        <Typography
                          variant={guidedChoice ? 'p20SemiBold' : 'p20SemiBold'}
                          color='secondary.dark'>
                          {price}
                        </Typography>
                      </>
                    )}
                    {selectedPrice?.intervalType !== IntervalTypeEnum.None && !freeTrial30Day && (
                      <Typography
                        variant={guidedChoice ? 'body1' : 'h3'}
                        color={guidedChoice ? 'info.main' : 'secondary'}>
                        / {selectedPrice?.intervalType}
                      </Typography>
                    )}
                  </Row>
                  <Typography variant='p12SemiBold' color='secondary.main'>
                    {freeTrial30Day
                      ? 'then $14.99/month'
                      : selectedPrice?.intervalType === IntervalTypeEnum.Month
                      ? `auto-renews monthly`
                      : selectedPrice?.intervalType === IntervalTypeEnum.Year
                      ? `auto-renews annually`
                      : price === 0 || orgPays
                      ? `free`
                      : `one-time payment`}
                    {meteredPayment && `*`}
                  </Typography>
                </Column>
              </CardContent>
            </Card>
          </Row>
        </CardContent>
      </Card>

      {promotion ? (
        <>
          <Spacer height='xxs' />
          <Typography variant='p14' color='secondary'>
            * After {introPeriodInMonths === 1 ? '30 days' : introPeriodInMonths}
            {introPeriodInMonths > 1 && ' months'}, Advice subscription auto-renews at $14.99/month
          </Typography>
        </>
      ) : meteredPayment ? (
        <Typography color='info.main' variant='body2'>
          * An invoice will be sent at the end of each month for any new or renewing licenses.
        </Typography>
      ) : (
        <Spacer height='xs' />
      )}
    </>
  );
};
