import React, { ReactNode } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { HorizontalFormInput, Spacer } from '.';
import { Form } from 'react-final-form';

interface PaymentDetailsProps {
  title?: string;
  formFields: Array<{
    initialValue: string;
    label: string;
    fieldName: string;
    inputStyle?: React.CSSProperties;
  }>;
  summaryContent: ReactNode;
  hideSummary?: boolean;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  title,
  formFields,
  summaryContent,
  hideSummary,
}) => {
  const formContent = (
    <>
      {formFields.map((field, index) => (
        <>
          <HorizontalFormInput
            readonly={true}
            labelStyle={{
              flexDirection: 'row',
              textAlign: 'right',
              alignSelf: 'flex-start',
              justifyContent: 'flex-end',
              flex: title || hideSummary ? '2 0 10px' : '3 0 10px',
              lineHeight: '1.2',
            }}
            inputStyle={{
              ...field.inputStyle,
              alignSelf: 'flex-start',
              flex: 2,
              flexDirection: 'row',
              lineHeight: '1.2',
              paddingTop: '0.16em',
              marginBottom: 'calc(-0.16em + 10px)',
            }}
            extraStyles={{ width: '100%' }}
            initialValue={field.initialValue}
            label={field.label}
            fieldName={field.fieldName}
            rightAlignedKey={true}
          />
          {index < formFields.length && <Spacer height='sm' />}
        </>
      ))}
    </>
  );

  return (
    <PaymentDetailsCard>
      <Grid container alignItems='center' justifyContent='space-between' flexDirection='row'>
        <PaymentDetailsForm title={title}>{formContent}</PaymentDetailsForm>
        {!hideSummary && <PaymentSummaryCard>{summaryContent}</PaymentSummaryCard>}
      </Grid>
    </PaymentDetailsCard>
  );
};

const PaymentDetailsCard: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Card variant='primaryLight' style={{ display: 'flex', flex: 1 }}>
    <Grid container py={3}>
      {children}
    </Grid>
  </Card>
);

const PaymentDetailsForm: React.FC<{ title?: string; children: ReactNode }> = ({
  title,
  children,
}) => (
  <Form onSubmit={() => {}}>
    {() => (
      <Grid display='flex' flex={1.5} ml={1} alignItems='flex-start' flexDirection='column'>
        <Grid container maxWidth='md'>
          {title && (
            <HorizontalFormInput
              readonly={true}
              labelStyle={{ marginBottom: 5 }}
              inputStyle={{ flex: 2 }}
              extraStyles={{ width: '100%' }}
              textSize='title'
              label={title}
              fieldName={'title'}
              rightAlignedKey={true}
            />
          )}
          {children}
        </Grid>
      </Grid>
    )}
  </Form>
);

const PaymentSummaryCard: React.FC<{ children: ReactNode }> = ({ children }) => (
  <Grid display='flex' flex={1} alignItems='center' justifyContent='flex-start' width='100%'>
    <Grid item display='flex' flex={1} justifyContent='flex-end' xs={10}>
      <Card variant='ghost' style={{ minWidth: '200px' }}>
        <CardContent
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 15,
          }}>
          {children}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
);

export default PaymentDetails;
