import * as React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { Svgs } from '../assets/svg';
import { Spacer } from '.';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '10px',
  },
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  onClose: () => void;
}

function BootstrapHeader(props: DialogTitleProps) {
  const { onClose } = props;
  return (
    <IconButton
      aria-label='close'
      onClick={onClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500],
      }}>
      <Svgs.IconClose />
    </IconButton>
  );
}

interface CustomizedDialogsProps extends React.PropsWithChildren {
  title?: string;
  handleSave?: (data?: any) => void;
  data?: any;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hideButtons?: boolean;
  fullWidth?: boolean;
  contentStyle?: SxProps<Theme>;
}

const CustomizedDialogs: React.FC<CustomizedDialogsProps> = props => {
  const {
    title,
    handleSave,
    open,
    setOpen,
    data,
    primaryButtonText,
    secondaryButtonText,
    hideButtons,
    fullWidth,
    contentStyle,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };
  const onSave = () => {
    if (handleSave) {
      if (data) {
        handleSave(data);
      } else {
        handleSave();
      }
    }
    handleClose();
  };

  return (
    <div style={{ borderRadius: '10px' }}>
      <BootstrapDialog
        sx={{ padding: '20px' }}
        fullWidth={fullWidth}
        maxWidth='lg'
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}>
        <BootstrapHeader id='customized-dialog-title' onClose={handleClose} />
        <Spacer height='sm' />
        <DialogContent sx={{ padding: '20px 50px 20px 20px !important', ...contentStyle }}>
          <Typography variant='p32Light' color='secondary.main'>
            {title}
          </Typography>
          <Spacer height='sm' />
          {props.children}
        </DialogContent>
        {!hideButtons && (
          <DialogActions sx={{ justifyContent: 'space-between', margin: '0px 20px 20px 20px' }}>
            <Button color='secondary' variant='outlined' onClick={handleClose}>
              {secondaryButtonText ? secondaryButtonText : `Cancel`}
            </Button>
            <Button color='primary' onClick={onSave}>
              {primaryButtonText ? primaryButtonText : `Yes`}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
};

export default CustomizedDialogs;
