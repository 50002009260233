import React from 'react';
import { Grid, Typography } from '@mui/material';
import { formatDollars, formatTwoDecimalPlaces } from '../../helpers/utilityFunctions';
import { IntervalTypeEnum, PricebookBundle } from '../../models/PricebookData';
import PaymentDetails from '../../components/PaymentDetails';

interface LicensePaymentDetailsProps {
  selectedPricebook: PricebookBundle | undefined;
  licenseQuantity: string;
  addedLicenses: number;
  costPerLicense: number | undefined;
  invoiceDate: string | undefined;
  hideSummary?: boolean;
}

const LicensePaymentDetails: React.FC<LicensePaymentDetailsProps> = ({
  selectedPricebook,
  licenseQuantity,
  addedLicenses,
  costPerLicense,
  invoiceDate,
  hideSummary,
}) => {
  const activeSubscription = selectedPricebook?.prices.find(
    price => price.intervalType !== IntervalTypeEnum.None,
  );
  const price = costPerLicense ?? (activeSubscription && activeSubscription.price / 100.0) ?? 0;
  const totalPerPeriod = parseInt(licenseQuantity) * price;

  const formFields = [
    {
      initialValue: invoiceDate || '',
      label: 'Auto-payment date',
      fieldName: 'autoPaymentDate',
    },
    {
      initialValue: `$${price}/${
        activeSubscription?.intervalType === IntervalTypeEnum.Year ? 'yr' : 'mo'
      }`,
      label: 'Cost per license per member',
      fieldName: 'costPerLicense',
    },
    {
      initialValue: formatDollars(totalPerPeriod),
      label: `Your new total per ${activeSubscription?.intervalType.toLocaleLowerCase()}`,
      fieldName: 'numberOfLicenses',
      inputStyle: { fontWeight: 'bold' },
    },
  ];

  const summaryContent = (
    <>
      <Grid container justifyContent='center'>
        <Typography color='info.main' style={{ display: 'inline' }} variant='subtitle1'>
          $
        </Typography>
        <Typography color='secondary.dark' style={{ display: 'inline' }} variant='subtitle1'>
          {formatTwoDecimalPlaces(addedLicenses * price)}
        </Typography>
      </Grid>
      <Grid justifyContent='center'>
        <Typography variant='p12SemiBold' color='secondary.main'>
          {'due today*'}
        </Typography>
      </Grid>
    </>
  );

  return (
    <PaymentDetails
      formFields={formFields}
      summaryContent={summaryContent}
      hideSummary={hideSummary}
    />
  );
};

export default LicensePaymentDetails;
