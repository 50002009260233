import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Svgs } from '../assets/svg';
import FormInputNumber from './FormInputNumber';
import { useField } from 'react-final-form';

interface NumericStepperProps {
  label: string;
  fieldName: string;
  min?: number;
  max?: number;
}

const NumericStepper: React.FC<NumericStepperProps> = ({
  label,
  fieldName,
  min = 0,
  max = 9999999,
}) => {
  const theme = useTheme();
  const { input } = useField(fieldName);

  const handleChange = (newValue: number) => {
    input.onChange(newValue);
  };

  return (
    <Grid container direction='column' alignItems='center' mb={-1}>
      <Grid container alignItems='center' justifyContent='center' spacing={1}>
        <Grid item>
          <IconButton
            size='small'
            onClick={() => {
              handleChange(Math.max(min, (input.value || 0) - 1));
            }}>
            <Svgs.IconSubtract fill={theme.palette.info.main} />
          </IconButton>
        </Grid>
        <Grid item xs>
          <FormInputNumber
            fieldName={fieldName}
            variant='outlined'
            min={min}
            max={max}
            InputProps={{
              style: {
                backgroundColor: 'white',
              },
              inputProps: {
                style: {
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: theme.palette.secondary.main,
                },
              },
            }}
          />
        </Grid>
        <Grid item>
          <IconButton
            size='small'
            onClick={() => {
              handleChange(Math.min(max, (input.value || 0) + 1));
            }}>
            <Svgs.IconAdd fill={theme.palette.info.main} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item mt={1}>
        <Typography variant='p12SemiBold' color='secondary.main'>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NumericStepper;
