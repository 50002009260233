import { Typography, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, StepsHeader, Spacer, Row } from '../../components';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { useService } from '@aesop-fables/containr-react';
import { LicenseCompartments, licenseStorageKey } from '../../data/license';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { ProductTypeCompartments, productTypeStorageKey } from '../../data/productType';
import { IntervalTypeEnum, PaymentTypeEnum, PricebookBundle } from '../../models/PricebookData';
import { LicenseData } from '../../models/LicenseData';
import { SelectPricebook } from '../../data/pricebook/mutations';
import { LicenseAgreementApi, LicenseAgreementData } from '../../api/apis/LicenseAgreementApi';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { useLoading } from '../../hooks/useLoading';
import { StepsHeaderTypes } from '../../models/StepsHeaderData';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { UpdateSignUpState } from '../../data/signUp/mutations';
import { OrgSignUpStateData } from '../../models/SignUpData';
import { SignUpWizard, signUpWizardKey } from '../../services/signUp';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import HowManyLicenses from '../../sign-up/license/HowManyLicenses';
import ProductOptions from '../../sign-up/license/ProductOptions';
import CancelContract from '../../components/CancelContract';

const AddPackage: React.FC = () => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { setToast } = useContext(ToastbarContext);

  const licenseAgreementApi = useService<LicenseAgreementApi>(ApiKeys.LicenseAgreement);
  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const selectPricebook = useMutation(new SelectPricebook());
  const updateSignUpState = useMutation(new UpdateSignUpState());
  const licenseDataCache = appStorage.retrieve<LicenseCompartments>(licenseStorageKey);
  const pricebookDataCache = appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);
  const productTypeDataCache = appStorage.retrieve<ProductTypeCompartments>(productTypeStorageKey);
  const signUpDataCache = appStorage.retrieve<SignUpCompartments>(signUpStorageKey);

  // eslint-disable-next-line
  const pricebookOptions =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions')) ?? [];
  const pricebookSelections =
    useObservable(pricebookDataCache.observe$<PricebookBundle[]>('pricebookSelections')) ?? [];
  const orgSignUpState = useObservable(
    signUpDataCache.observe$<OrgSignUpStateData>('orgSignUpState'),
  );
  const licenseAgreements = useObservable(
    licenseDataCache.observe$<LicenseAgreementData[]>('licenseAgreement'),
  );

  const [selectedPricebook, setSelectedPricebook] = useState<PricebookBundle | undefined>();
  const [hasSetPricebook, setHasSetPricebook] = useState(false);
  const [memberPaidOptions, setMemberPaidOptions] = useState<PricebookBundle[]>([]);
  const [orgPaidOptions, setOrgPaidOptions] = useState<PricebookBundle[]>([]);
  const paidBy = useObservable(productTypeDataCache.observe$<string>('paidBy'));
  const meteredPayment = selectedPricebook?.pricebook.paymentType === PaymentTypeEnum.Metered;
  const pricebook: PricebookBundle | undefined =
    orgSignUpState?.state?.pricebook && JSON.parse(orgSignUpState?.state.pricebook);
  const checkedOut =
    orgSignUpState?.state?.checkedOut && JSON.parse(orgSignUpState?.state.checkedOut);
  const licenseData: LicenseData =
    orgSignUpState?.state?.licenseData && JSON.parse(orgSignUpState?.state.licenseData);
  const currentLicense =
    licenseAgreements &&
    licenseAgreements?.find(
      license => license.pricebookId === selectedPricebook?.pricebook.id && license.docStatus,
    );
  const activeSubscription = selectedPricebook?.prices.find(
    price => price.intervalType !== IntervalTypeEnum.None,
  );
  const price = (activeSubscription && activeSubscription.price / 100.0) ?? 0;
  const pendingAgreement = currentLicense?.docStatus && !currentLicense?.hasAccepted;
  const activeContract = pendingAgreement || currentLicense?.docStatus;
  const activeStep = 1;
  const totalSteps = 2;

  useEffect(() => {
    if (pricebook && !hasSetPricebook) {
      setSelectedPricebook(pricebook);
      setHasSetPricebook(true);
    }
  }, [pricebook, hasSetPricebook, checkedOut]);

  useEffect(() => {
    const getPricebookOptions = async () => {
      if (pricebookOptions) {
        const member = pricebookOptions.filter(bundle => bundle.pricebook.paidBy === 'USER');
        const org = pricebookOptions.filter(bundle => bundle.pricebook.paidBy === 'ORG');
        if (paidBy === 'USER') {
          setMemberPaidOptions(member);
        } else if (paidBy === 'ORG') {
          setOrgPaidOptions(org);
        } else {
          setMemberPaidOptions(member);
          setOrgPaidOptions(org);
        }
      }
    };
    getPricebookOptions();
  }, [paidBy, pricebookOptions]);

  const navBack = () => {
    navigate('/license-agreement');
  };

  const handleSubmit = async (values: LicenseData) => {
    if (!selectedPricebook) return;

    try {
      setLoading(true);
      const licenseData: LicenseData = {
        ...values,
        paymentParty: selectedPricebook.pricebook.paidBy,
        package: selectedPricebook.pricebook.productType,
      };
      if (licenseData.paymentParty === 'USER') {
        licenseData.quantity = undefined;
      } else {
        if (typeof values.quantity === 'string') {
          licenseData.quantity = parseInt(values.quantity);
        }
        licenseData.cost = price;
      }

      await selectPricebook.action(selectedPricebook);
      const generatedLicense = (await licenseAgreementApi.generate(selectedPricebook.pricebook.id))
        .data;
      await updateSignUpState.action({
        lastCompletedStep: signUpWizard.getCurrent(location),
        state: {
          ...orgSignUpState?.state,
          stripeSession: JSON.stringify(undefined),
          pricebook: JSON.stringify(selectedPricebook),
          licenseData: JSON.stringify({ ...licenseData, ...generatedLicense }),
        },
      });
      await licenseDataCache.reloadAll();
      navigate('contract', {
        state: {
          activeStep: activeStep + 1,
          totalSteps: totalSteps,
        },
      });
    } catch (error) {
      console.error('Failed to select pricebook with id: ' + selectedPricebook.pricebook.id);
      setToast(
        new Toast({
          message: `Failed to select package`,
          severity: 'error',
          open: true,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={{ quantity: licenseData?.quantity }}>
      {({ handleSubmit, values }) => (
        <Column style={{ margin: '15px 100px' }}>
          <StepsHeader
            activeStep={activeStep}
            totalSteps={totalSteps}
            headerSteps={StepsHeaderTypes.AddPackage}
          />
          <Typography variant='h1' color='secondary'>
            Step {activeStep}: Choose a package
          </Typography>
          <Spacer height='sm' />
          <Typography variant='p24SemiBold' color='secondary'>
            Which package would you like to add for your members?
          </Typography>
          <Spacer height='xs' />
          <ProductOptions
            memberPaidOptions={memberPaidOptions}
            orgPaidOptions={orgPaidOptions}
            selectedPricebook={selectedPricebook}
            setSelectedPricebook={setSelectedPricebook}
            pricebookSelections={pricebookSelections}
            currentLicense={currentLicense}
            signUpState={orgSignUpState?.state}
            updateSignUpState={updateSignUpState}
          />
          {!meteredPayment && selectedPricebook?.pricebook.paidBy === 'ORG' && (
            <HowManyLicenses values={values} cost={price} />
          )}
          <Spacer height='lg' />
          <Row style={{ justifyContent: 'space-between' }}>
            <Button onClick={navBack} className='xs' variant='outlined' color='secondary'>
              Cancel
            </Button>
            <Row style={{ alignItems: 'center', width: '100%', justifyContent: 'flex-end' }}>
              {activeContract && <CancelContract />}
              <Spacer width='sm' />
              <Button
                disabled={!selectedPricebook}
                onClick={handleSubmit}
                className='md'
                color='primary'>
                Next: Review
              </Button>
            </Row>
          </Row>
          <Spacer height='md' />
        </Column>
      )}
    </Form>
  );
};

export default AddPackage;
