import { useService } from '@aesop-fables/containr-react';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { Typography, Button, Grid, Link, useTheme } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Column, Spacer, StepsHeader, InviteAdminsDrawer, Row } from '../components';
import { useDeviceType } from '../hooks/useDeviceType';
import { SignUpWizard, signUpWizardKey } from '../services/signUp';
import { AdminTierTypeEnum } from '../services/drillDown';
import { AddOrgAdmin, RemoveOrgAdmin } from '../data/admin/mutations';
import AdminDataTable from '../components/AdminDataTable';
import { AdminCompartments, adminStorageKey } from '../data/admin';
import { UserData } from '../models/UserData';
import { StepsHeaderTypes } from '../models/StepsHeaderData';
import { HelpOutline } from '@mui/icons-material';
import Tippy from '@tippyjs/react';
import { UserCompartments, userStorageKey } from '../data/user';
import { useAdminFilters } from '../hooks/useAdminHooks';

const InviteOtherAdmins = () => {
  const appStorage = useAppStorage();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const signUpWizard = useService<SignUpWizard>(signUpWizardKey);
  const userDataCache = appStorage.retrieve<UserCompartments>(userStorageKey);
  const adminDataCache = appStorage.retrieve<AdminCompartments>(adminStorageKey);
  const currentUser = useObservable(userDataCache.observe$<UserData>('user'));
  const orgAdmins = useObservable(adminDataCache.observe$<UserData[]>('orgAdmins'));

  const { filterAdmins } = useAdminFilters();
  const filteredAdmins = filterAdmins(orgAdmins, currentUser);
  const { activeStep, totalSteps } = signUpWizard.currentStep(location);
  const [openDrawer, setOpenDrawer] = useState(false);

  const navToNextStep = () => {
    const next = signUpWizard.nextStep(location);
    navigate(next?.route ?? '');
  };

  const navBack = () => {
    const step = signUpWizard.previousStep(location);
    navigate(step?.route ?? '');
  };

  const { isMobile, isTablet } = useDeviceType();
  return (
    <Column style={{ margin: isMobile || isTablet ? '0px 20px' : '0px 100px' }}>
      <Spacer height='xs' />
      <StepsHeader
        activeStep={activeStep}
        totalSteps={totalSteps}
        headerSteps={StepsHeaderTypes.Onboarding}
      />
      <Spacer height='xs' />
      <Typography variant='h1' color='secondary'>
        Step {activeStep}: Invite other admins
      </Typography>
      <Spacer height='xs' />
      <Row>
        <Typography variant='p18' color='info.main'>
          Give access to other people in your organization.&nbsp;
        </Typography>
        <Tippy placement='right' content={<InviteOtherAdminsHelpText />} theme='tippy-rounded'>
          <span style={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer' }}>
            <Link variant='p18' color='info.main'>
              Why invite other admins?
            </Link>
            <Spacer width='xxxs' />
            <HelpOutline style={{ color: theme.palette.info.main }} />
          </span>
        </Tippy>
      </Row>

      <Spacer height='sm' />
      <Grid>
        <Button onClick={() => setOpenDrawer(true)} color='secondary'>
          Invite to organization
        </Button>
      </Grid>
      <InviteAdminsDrawer
        drawerOpen={openDrawer}
        setDrawerOpen={setOpenDrawer}
        role={AdminTierTypeEnum.Organization}
        addAdmin={useMutation(new AddOrgAdmin())}
      />
      <AdminDataTable
        role={AdminTierTypeEnum.Organization}
        currentUser={currentUser}
        usersList={filteredAdmins ?? []}
        removeAdmin={useMutation(new RemoveOrgAdmin())}
        hideSearch
        loading={!filteredAdmins}
      />
      {isMobile ? (
        <Column style={{ alignItems: 'center' }}>
          <Button style={{ width: '100%' }} className='sm' onClick={navToNextStep} color='primary'>
            Next: Review
          </Button>
          <Spacer height='xs' />
          <Button
            style={{ width: '100%' }}
            className='xs'
            onClick={navBack}
            variant='outlined'
            color='secondary'>
            Back
          </Button>
        </Column>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          <Button className='xs' onClick={navBack} variant='outlined' color='secondary'>
            Back
          </Button>
          <Button className='sm' onClick={navToNextStep} color='primary'>
            Next: Products
          </Button>
        </Row>
      )}
    </Column>
  );
};

export default InviteOtherAdmins;

export const InviteOtherAdminsHelpText = () => {
  const bullets = ['•	billing/payments', '•	product selection', '•	product/program management'];

  return (
    <Column>
      <Typography variant='p18Bold' color='secondary.dark'>
        Why invite other admins?
      </Typography>
      <Spacer height='xs' />
      <Typography variant='p18Light' color='secondary.dark'>
        Consider inviting additional members from your organization, especially if they are involved
        in
      </Typography>
      <Spacer height='xxs' />
      <Column style={{ marginLeft: '20px' }}>
        {bullets.map(bullet => (
          <Typography key={bullets.indexOf(bullet)} variant='p18Light' color='secondary.dark'>
            {bullet}
          </Typography>
        ))}
      </Column>
      <Spacer height='xxs' />
    </Column>
  );
};
