import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { convertToTitleCase, formatDollars } from '../../../helpers/utilityFunctions';
import { PaymentMethodData } from '../../../api/apis/AdminPaymentApi';
import { IntervalTypeEnum, PricebookBundle } from '../../../models/PricebookData';
import { Spacer } from '../../../components';
import { LicenseAgreementData } from '../../../api/apis/LicenseAgreementApi';

interface SubscriptionDetailsProps {
  activeOrgPricebooks: LicenseAgreementData[] | undefined;
  defaultPaymentMethod: PaymentMethodData | undefined;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
  activeOrgPricebooks,
  defaultPaymentMethod,
}) => {
  return (
    <Grid container flexDirection='column' alignItems='center'>
      <Typography variant='p18SemiBold' color='secondary.dark'>
        Ongoing subscription details
      </Typography>
      {activeOrgPricebooks?.map(pricebook => (
        <>
          <SubscriptionTable
            pricebook={pricebook.pricebookBundleDto}
            defaultPaymentMethod={defaultPaymentMethod}
          />
          <Spacer height='xxs' />
        </>
      ))}
    </Grid>
  );
};

interface SubscriptionTableProps {
  pricebook: PricebookBundle | undefined;
  defaultPaymentMethod: PaymentMethodData | undefined;
}
const SubscriptionTable: React.FC<SubscriptionTableProps> = ({
  pricebook,
  defaultPaymentMethod,
}) => {
  const activeSubscription = pricebook?.prices.find(
    price => price.intervalType !== IntervalTypeEnum.None,
  );
  const price = (activeSubscription && activeSubscription.price / 100.0) ?? 0;

  return (
    <Grid container flexDirection='column' alignItems='center'>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableCell variant='dataHead' style={{ width: '50%' }} />
          <TableBody>
            <TableRow>
              <TableCell variant='dataBodyKey'>Package</TableCell>
              <TableCell variant='dataBodyValue'>{pricebook?.pricebook.pricebookName}</TableCell>
            </TableRow>
            {defaultPaymentMethod && (
              <TableRow>
                <TableCell variant='dataBodyKey'>Default payment method</TableCell>
                <TableCell variant='dataBodyValue'>
                  {convertToTitleCase(defaultPaymentMethod.type)} ending in{' '}
                  {defaultPaymentMethod.lastDigits}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell variant='dataBodyKey'>Auto-payment date</TableCell>
              <TableCell variant='dataBodyValue'>Last day of each month</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='dataBodyKey'>Cost per license per member</TableCell>
              <TableCell variant='dataBodyValue'>{formatDollars(price)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SubscriptionDetails;
