import { Svgs } from '../assets/svg';
import { ChartBarData } from '../components/ChartBar';
import { ProductTypeEnum } from '../models/PricebookData';
import {
  AnyReportType,
  PricebookReport,
  ProgressData,
  Progress3nuData,
  ReportChart,
  BaseReportData,
} from '../models/ReportData';

// Types and Constants
export enum AccountState {
  activeUserCount = 'Active',
  abandonedCount = 'Abandoned',
  ineligibleCount = 'Ineligible',
  invitedCount = 'Invited',
  freeTrialPaymentPlatformCount = 'In trial period',
  unsubscribedCount = 'Unsubscribed',
  undecidedCount = 'Undecided',
}

export type AccountStateKey = keyof typeof AccountState;

export interface UserAccountsTableData {
  [key: string]: {
    [K in AccountStateKey]: number;
  };
}

export type AccountType = 'Subscription' | 'Free';

const paidProducts: ProductTypeEnum[] = [
  ProductTypeEnum.GuidedChoice,
  ProductTypeEnum.Nickels,
  ProductTypeEnum.NickelsU,
];

// Basic Utility Functions
export const getProductTypeKey = (productType: ProductTypeEnum | undefined): string => {
  if (!productType) return '';
  return productType === ProductTypeEnum.Nickels || productType === ProductTypeEnum.NickelsU
    ? '3Nickels'
    : productType?.charAt(0).toUpperCase() +
        productType
          .slice(1)
          .toLowerCase()
          .replace(/_./g, match => match[1].toUpperCase());
};

export const isPaidProduct = (productType: ProductTypeEnum): boolean => {
  return paidProducts.includes(productType);
};

export const getAccountType = (productType: ProductTypeEnum): AccountType => {
  return isPaidProduct(productType) ? 'Subscription' : 'Free';
};

// Data Extraction and Processing
export const getPricebookReportsByType = (
  report: AnyReportType | null,
  productTypes?: string | string[],
): PricebookReport[] => {
  if (!report) return [];

  const productTypesArray: string[] = productTypes
    ? Array.isArray(productTypes)
      ? productTypes
      : [productTypes]
    : (Object.values(ProductTypeEnum) as ProductTypeEnum[]);

  const allPricebookReports: PricebookReport[] = [];

  if ('pricebookReports' in report && Array.isArray(report.pricebookReports)) {
    allPricebookReports.push(...report.pricebookReports);
  }

  if ('organizationReports' in report && Array.isArray(report.organizationReports)) {
    report.organizationReports.forEach(org => {
      if (org && 'pricebookReports' in org && Array.isArray(org.pricebookReports)) {
        allPricebookReports.push(...org.pricebookReports);
      }
    });
  }

  if ('teamReports' in report && Array.isArray(report.teamReports)) {
    report.teamReports.forEach(team => {
      if (team && 'pricebookReports' in team && Array.isArray(team.pricebookReports)) {
        allPricebookReports.push(...team.pricebookReports);
      }
    });
  }

  return allPricebookReports.filter(report => {
    const reportProductType = report.productType as string;
    return productTypesArray.some(
      type => type.toString().toLowerCase() === reportProductType.toLowerCase(),
    );
  });
};

export const getIndividualReports = (report: AnyReportType): AnyReportType[] => {
  if ('organizationReports' in report) {
    return report.organizationReports;
  } else if ('teamReports' in report) {
    return report.teamReports;
  } else {
    return [report];
  }
};

export const getUniqueProductTypes = (report: AnyReportType | null): string[] => {
  if (!report) return [];

  const pricebookReports = getPricebookReportsByType(report);
  if (!pricebookReports.some(product => product.productType)) return [];

  return [...new Set(pricebookReports.map(report => getProductTypeKey(report?.productType)))];
};

export const getColumnData = (report: AnyReportType): UserAccountsTableData => {
  const columnData: UserAccountsTableData = {};
  const pricebookReports = getPricebookReportsByType(report);

  pricebookReports.forEach(pricebookReport => {
    if (pricebookReport.productType && (pricebookReport.productType as string) !== 'TOTAL') {
      const productType = getProductTypeKey(pricebookReport.productType);
      columnData[productType] = processReportData(pricebookReport);
    }
  });

  columnData['Total'] = processTotalData(pricebookReports, report);

  columnData['Unknown'] = processUnknownData(report);

  return columnData;
};

// Helpers
const processReportData = (report: PricebookReport): UserAccountsTableData[string] => {
  return {
    activeUserCount: report.activeUserCount || 0,
    ineligibleCount: report.ineligibleCount || 0,
    freeTrialPaymentPlatformCount: report.freeTrialPaymentPlatformCount || 0,
    unsubscribedCount: report.unsubscribedCount || 0,
    abandonedCount: 0,
    invitedCount: 0,
    undecidedCount: 0,
  };
};

const processTotalData = (
  pricebookReports: PricebookReport[],
  parentReport: AnyReportType,
): UserAccountsTableData[string] => {
  const totalReport = getPricebookReportsByType(parentReport, 'TOTAL')[0];

  if (totalReport) {
    return {
      activeUserCount: totalReport.activeUserCount || 0,
      ineligibleCount: totalReport.ineligibleCount || 0,
      freeTrialPaymentPlatformCount: totalReport.freeTrialPaymentPlatformCount || 0,
      unsubscribedCount: totalReport.unsubscribedCount || 0,
      abandonedCount: (parentReport as BaseReportData).abandonedCount || 0,
      invitedCount: (parentReport as BaseReportData).invitedCount || 0,
      undecidedCount: (parentReport as BaseReportData).undecidedCount || 0,
    };
  } else {
    return {
      activeUserCount: pricebookReports.reduce((sum, r) => sum + (r.activeUserCount || 0), 0),
      ineligibleCount: pricebookReports.reduce((sum, r) => sum + (r.ineligibleCount || 0), 0),
      freeTrialPaymentPlatformCount: pricebookReports.reduce(
        (sum, r) => sum + (r.freeTrialPaymentPlatformCount || 0),
        0,
      ),
      unsubscribedCount: pricebookReports.reduce((sum, r) => sum + (r.unsubscribedCount || 0), 0),
      abandonedCount: (parentReport as BaseReportData).abandonedCount || 0,
      invitedCount: (parentReport as BaseReportData).invitedCount || 0,
      undecidedCount: (parentReport as BaseReportData).undecidedCount || 0,
    };
  }
};

const processUnknownData = (report: AnyReportType): UserAccountsTableData[string] => {
  return {
    activeUserCount: 0,
    abandonedCount: (report as BaseReportData).abandonedCount || 0,
    ineligibleCount: (report as BaseReportData).unknownIneligibleCount || 0,
    invitedCount: (report as BaseReportData).invitedCount || 0,
    freeTrialPaymentPlatformCount: 0,
    unsubscribedCount: 0,
    undecidedCount: (report as BaseReportData).undecidedCount || 0,
  };
};

// Data Manipulation
export const alphabetizeReports = (reports: AnyReportType[]): AnyReportType[] => {
  return reports.sort((a, b) => {
    const nameA = 'name' in a ? a.name : '';
    const nameB = 'name' in b ? b.name : '';
    return nameA.localeCompare(nameB);
  });
};

export const paginateReports = (
  reports: AnyReportType[],
  page: number,
  pageSize: number,
): AnyReportType[] => {
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return reports.slice(startIndex, endIndex);
};

// Data Calculation
export const sumNestedProperty = (obj: any, property: string): number => {
  if (typeof obj !== 'object' || obj === null) {
    return 0;
  }

  let sum = 0;

  if (property in obj && typeof obj[property] === 'number') {
    sum += obj[property];
  }

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      sum += sumNestedProperty(obj[key], property);
    }
  }

  return sum;
};

export const sumPropertyInArray = (array: any[], property: string): number => {
  if (!Array.isArray(array)) {
    return 0;
  }

  return array.reduce((sum, item) => {
    if (item && typeof item === 'object' && property in item) {
      const value = item[property];
      return sum + (typeof value === 'number' ? value : 0);
    }
    return sum;
  }, 0);
};

// UI Helpers
export const getIcon = (accountType: AccountState) => {
  switch (accountType) {
    case AccountState.activeUserCount:
      return <Svgs.IconStatusActive />;
    case AccountState.abandonedCount:
      return <Svgs.IconStatusAbandoned />;
    case AccountState.invitedCount:
      return <Svgs.IconStatusInvited />;
    case AccountState.ineligibleCount:
      return <Svgs.IconStatusIneligible />;
    case AccountState.freeTrialPaymentPlatformCount:
      return <Svgs.IconStatusTrial />;
    case AccountState.undecidedCount:
      return <Svgs.IconStatusUndecided />;
    case AccountState.unsubscribedCount:
      return <Svgs.IconStatusUnsubscribed />;
    default:
      return null;
  }
};

// Visualization Helpers
export const getReportChartData = (
  report: AnyReportType,
  keyFn: (pricebookReport: PricebookReport) => string,
  dataMap: Map<keyof PricebookReport, string>,
): ReportChart => {
  const result: ReportChart = {};
  const categories = [...dataMap.values()];
  const pricebookReports = getPricebookReportsByType(report);

  pricebookReports.forEach((pricebookReport: PricebookReport) => {
    if (!pricebookReport) return;

    const key = keyFn(pricebookReport);

    const hasData = Array.from(dataMap.keys()).some(dataKey => {
      const value = pricebookReport[dataKey];
      return value !== null && value !== 0;
    });

    if (hasData) {
      if (!result[key]) {
        result[key] = initializeChartData(pricebookReport, dataMap);
      } else {
        result[key] = updateChartData(result[key], pricebookReport, dataMap);
      }
    }
  });

  // create empty 'Default' entry if no data
  if (Object.keys(result).length === 0) {
    result['Default'] = categories.map(category => ({ x: category, y: 0 }));
  }

  return result;
};

const initializeChartData = (
  pricebookReport: PricebookReport,
  dataMap: Map<keyof PricebookReport, string>,
): ChartBarData[] => {
  return Array.from(dataMap).map(([key, label]) => ({
    x: label,
    y: (pricebookReport[key] as number | null) ?? 0,
  }));
};

const updateChartData = (
  prevData: ChartBarData[],
  pricebookReport: PricebookReport,
  dataMap: Map<keyof PricebookReport, string>,
): ChartBarData[] => {
  if (!pricebookReport) return prevData;

  const prevDataMap = new Map(prevData.map(entry => [entry.x, entry.y]));

  return Array.from(dataMap).map(([key, label]) => {
    const accumulatedVal = prevDataMap.get(label) ?? 0;
    const newVal = pricebookReport[key] as number | null;
    const updatedVal = (newVal ?? 0) + accumulatedVal;

    return {
      x: label,
      y: updatedVal,
    };
  });
};

// Data Validation
function hasTypeData<T>(report: AnyReportType, keys: (keyof T)[]): boolean {
  const hasTopLevelData = keys.some(key => report[key as keyof AnyReportType] != null);
  if (hasTopLevelData) return true;

  const pricebookReports = getPricebookReportsByType(report);
  return pricebookReports.some(pricebookReport =>
    keys.some(key => pricebookReport[key as keyof PricebookReport] != null),
  );
}

export const hasProgressData = (report: AnyReportType): boolean => {
  const progressKeys: (keyof ProgressData)[] = [
    'acceptedTosCount',
    'onboardingCompletedCount',
    'budgetDetailsCompletedCount',
    'accountDetailsCompleteCount',
    'personalDetailsCompleteCount',
  ];

  const progress3nuKeys: (keyof Progress3nuData)[] = [
    'notStarted3nuCount',
    'zeroToTwentyFour3nuCount',
    'twentyFiveToSeventyFive3nuCount',
    'seventySixToNinetyNine3nuCount',
    'nuCompletedCount',
    'average3nuProgress',
  ];

  const hasProgressData = hasTypeData<ProgressData>(report, progressKeys);
  const hasProgress3nuData = hasTypeData<Progress3nuData>(report, progress3nuKeys);

  return hasProgressData || hasProgress3nuData;
};
