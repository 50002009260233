import { TextField, TextFieldVariants, Typography } from '@mui/material';
import { useRef } from 'react';
import { useField } from 'react-final-form';
import { Row } from '.';
import { formatNumber } from '../helpers/utilityFunctions';

interface FormInputNumberProps<T> {
  fieldName: keyof T;
  variant?: TextFieldVariants;
  label?: string;
  labelStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  rightAlignedKey?: boolean;
  extraStyles?: React.CSSProperties;
  required?: boolean;
  validate?: (x: number) => undefined | string;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  initialValue?: number;
  onChange?: (x: number) => void;
  maxLength?: number;
  min?: number;
  max?: number;
  includeCommaDelimiter?: boolean;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  InputProps?: {
    style?: React.CSSProperties;
    inputProps?: {
      style?: React.CSSProperties;
    };
  };
}

export function FormInputNumber<T>(props: FormInputNumberProps<T>): JSX.Element {
  const {
    fieldName,
    variant,
    label,
    required,
    validate,
    disabled,
    readonly,
    placeholder,
    initialValue,
    onChange,
    startAdornment,
    endAdornment,
    InputProps,
  } = props;
  const { maxLength, min, max, includeCommaDelimiter = true } = props;
  const rowStyles = props.rightAlignedKey
    ? { flexWrap: 'wrap', gap: '20px', alignItems: 'center', ...props.extraStyles }
    : { alignItems: 'center', gap: '20px', ...props.extraStyles };

  const labelStyles = props.rightAlignedKey
    ? { flex: '2 0 10px', justifyContent: 'flex-end', ...props.labelStyle }
    : { ...props.labelStyle };

  const inputStyles = props.rightAlignedKey
    ? { flex: '2 0 10px', ...props.inputStyle }
    : { ...props.inputStyle };

  const inputRef = useRef<typeof TextField>(null);
  const { input, meta } = useField<number>(fieldName.toString(), {
    initialValue,
    validate: value => (!required || value ? (validate ? validate(value) : undefined) : 'Required'),
    subscription: { value: true, touched: true, error: true },
  });

  const convertToDisplay = (v: number | undefined) =>
    !v ? '' : includeCommaDelimiter ? formatNumber(v) : String(v);
  const value = input.value ?? 0;
  const delimiters = convertToDisplay(value).match(/(,)/g);
  const fixedMaxLength =
    value.toString().length > (maxLength ?? 0) ? value.toString().length : maxLength ?? 0;
  const maxLengthCalc = maxLength
    ? fixedMaxLength + (delimiters?.length ?? 0)
    : 19 + (delimiters?.length ?? 0);

  return (
    <Row style={{ ...rowStyles }}>
      {label && (
        <Row style={{ ...labelStyles }}>
          <Typography variant='body1' color='info.main'>
            {label}
          </Typography>
        </Row>
      )}
      <TextField
        InputProps={{
          startAdornment,
          endAdornment,
          ...InputProps,
          style: { ...InputProps?.style },
          inputProps: {
            ...InputProps?.inputProps,
            maxLength: maxLengthCalc,
            style: { ...InputProps?.inputProps?.style },
          },
        }}
        id='standard-basic'
        variant={variant ?? 'standard'}
        style={{ ...inputStyles }}
        placeholder={placeholder ?? '0'}
        value={convertToDisplay(value)}
        onChange={v => {
          const newValue = cleanWholeNumberStr(v.target.value, min, max);
          if (onChange) {
            onChange(newValue);
          }
          input.onChange(newValue);
        }}
        disabled={disabled || readonly}
        helperText={meta.touched ? meta.error : undefined}
        error={meta.touched && meta.error}
        inputRef={inputRef}
      />
    </Row>
  );
}

function cleanWholeNumberStr(strVal: string, min?: number, max?: number): number {
  if (!strVal) return 0;
  const numVal = parseInt(strVal.replace(/[^\d.]/g, ''), 10) || 0;
  if (min && numVal < min) return min;
  if (max && numVal > max) return max;
  return numVal;
}

export default FormInputNumber;
